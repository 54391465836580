<template>
	<div class="experts">
		<div class="experts__content">
			<div class="experts__title" id="exp">
				<span class="wrapper" :class="{'hidden': !readyAnim}">
					<span class="ml10">
						<span class="text-wrapper">
							<span class="lll">ч</span>
							<span class="lll">т</span>
							<span class="lll">о</span>
							<span class="lll lll--space"> </span>
							<span class="lll">д</span>
							<span class="lll">у</span>
							<span class="lll">м</span>
							<span class="lll">а</span>
							<span class="lll">ю</span>
							<span class="lll">т</span>
							<br>
							<span class="lll">э</span>
							<span class="lll">к</span>
							<span class="lll">с</span>
							<span class="lll">п</span>
							<span class="lll letter">e</span>
							<span class="lll">р</span>
							<span class="lll">т</span>
							<span class="lll">ы</span>
							<span class="lll">?</span>
						</span>
					</span>
				</span>
					<div class="experts__title-el">
						<svg width="100%" height="100%" viewBox="0 0 196 49" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M188.66 1.16401C157.781 1.16401 126.665 0.474249 95.8812 1.9238C71.3265 3.08006 46.6294 3.37223 23.9444 9.09968C9.55089 12.7337 3.10495 13.9802 1.49971 23.9579C-0.309651 35.2045 14.192 41.6724 30.8916 43.7127C62.6464 47.5924 98.0749 49.6147 130.393 46.4142C146.418 44.8272 163.526 43.7875 177.156 38.2253C196.543 30.3132 204.442 15.6692 178.5 8.33988C158.001 2.54809 131.223 5.27179 109.402 5.76501C89.9578 6.2045 71.894 11.1264 52.8536 12.561C39.7248 13.5501 26.8346 18.0651 18.6407 23.9579C11.1055 29.3771 21.0281 37.6757 29.9952 40.2092C40.3654 43.1391 53.2749 43.3803 64.5069 44.1348C80.5302 45.2112 97.3514 46.0843 113.511 45.9921C130.936 45.8926 156.422 45.6863 170.134 38.2253C179.17 33.3084 188.66 29.2896 188.66 21.6786C188.66 11.3783 173.271 8.1469 160.423 1.9238" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path4" :class="{'animated': drawline}" />
						</svg>
					</div>
			</div>
			<div class="experts__text" data-aos="fade-up" data-aos-duration="800" data-aos-delay="2000" data-aos-offset="-400">о том, как неологизмы, заимствования из других языков и даже мемы влияют <br>на наше общение, мы поговорили с экспертами.</div>
			<div class="experts__widget" data-aos="fade-up" data-aos-duration="800" data-aos-delay="2000" data-aos-offset="-400">
				<div class="experts__widget-text">Скрольте, чтобы увидеть <br>все карточки и кликайте, <br>чтобы прочитать статью</div>
			</div>
			<div class="swiper swiper-articles" data-aos="fade-up" data-aos-duration="800" data-aos-delay="2000" data-aos-offset="-400">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(1, 'https://www.maximonline.ru/guide/anglicizmy-mozhno-yuzat-ili-luchshe-skipat-id727904/')">
							<div class="card-article__title"><span class="line">Англицизмы</span>: хорошо <br>это или н<span class="letter">e</span>т?</div>
							<div class="card-article__bottom">
								<div class="card-article__text">Почему мы заменяем русские слова английскими <br>и нормально ли это.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article card-article--yellow" @click="openArticle(2, 'https://www.maximonline.ru/guide/kak-obshatsya-s-zumerami-podkasty-laiki-i-novye-platformy-id729837/')">
							<div class="card-article__title">Как <br>общаться <br>с зум<span class="letter">e</span>рами?</div>
							<div class="card-article__bottom">
								<div class="card-article__text">Как издания осваивают новые средства коммуникации, за что «прилетает» редакторам и чем полезна отмена лайков.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(3, 'https://www.maximonline.ru/guide/kak-feminitivy-stanovyatsya-novoi-normoi-i-stanovyatsya-li-id727900/')">
							<div class="card-article__title">Как <span class="line">ф<span class="letter">e</span>минитивы</span> становятся новой нормой</div>
							<div class="card-article__bottom">
								<div class="card-article__text">Разбираемся <br>в «редактрисах», «продавицах» <br>и «логистках».</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(4, 'https://www.maximonline.ru/guide/kak-memy-izmenili-russkii-yazyk-id729825/')">
							<div class="card-article__title">Как м<span class="letter">e</span>мы <span class="line">изм<span class="letter">e</span>нили</span> русский язык</div>
							<div class="card-article__bottom">
								<div class="card-article__text">Смешные картинки, вирусные видео <br>и русский язык.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article card-article--yellow" @click="openArticle(5, 'https://www.maximonline.ru/guide/kakim-yazykom-sovremennye-media-govoryat-s-chitatelyami-id729832/')">
							<div class="card-article__title">Каким языком совр<span class="letter">e</span>м<span class="letter">e</span>нны<span class="letter">e</span> м<span class="letter">e</span>диа говорят с читат<span class="letter">e</span>лями</div>
							<div class="card-article__bottom">
								<div class="card-article__text">О том, как русский язык «перемелет» новые медиа.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(6, 'https://www.maximonline.ru/longreads/kak-my-obshaemsya-v-socsetyakh-i-kak-eto-vliyaet-na-ustnuyu-rech-id726873/')">
							<div class="card-article__title">Как мы обща<span class="letter">e</span>мся в <span class="line">соцс<span class="letter">e</span>тях</span> и как это влия<span class="letter">e</span>т на устную р<span class="letter">e</span>чь?</div>
							<div class="card-article__bottom">
								<div class="card-article__text">О сетевом жаргоне, интимности мата и словах, которые бесят.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(7, 'https://www.maximonline.ru/guide/kak-yazyk-otobrazhaet-menyayushuyusya-realnost-razbiraemsya-vmeste-s-glavredom-maxim-aleksandrom-malenkovym-id730514/')">
							<div class="card-article__title">Как язык <span class="line">отобража<span class="letter">e</span>т</span> м<span class="letter">e</span>няющуюся р<span class="letter">e</span>альность?</div>
							<div class="card-article__bottom">
								<div class="card-article__text">Инсайты от главреда легендарного глянца.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article" @click="openArticle(8, 'https://www.maximonline.ru/guide/stanovitsya-li-russkii-yazyk-proshe-a-oshibki-prostitelnee-sprosili-u-filologov-id731518/')">
							<div class="card-article__title">Становится <br>ли русский язык <span class="line">прощ<span class="letter">e</span>?</span></div>
							<div class="card-article__bottom">
								<div class="card-article__text">Говорим о сокращениях, инфостиле и «кеках».</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article card-article--yellow" @click="openArticle(9, 'https://www.maximonline.ru/guide/ty-bolshoi-molodec-no-ya-nichego-ne-ponyal-kak-na-yazyk-vliyayut-tekhnologii-id732118/')">
							<div class="card-article__title">как на язык влияют т<span class="letter">e</span>хнологии?</div>
							<div class="card-article__bottom">
								<div class="card-article__text">О блогерах, «войсах» <br>и растущей грамотности.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="card-article card-article--yellow" @click="openArticle(10, 'https://www.woman.ru/news/zachem-vam-padezhi-chto-dumayut-inostrancy-o-russkom-yazyke-id731928/')">
							<div class="card-article__title">«Зач<span class="letter">e</span>м вам пад<span class="letter">e</span>жи?!»: <br>что думают <span class="line">иностранцы</span> о русском язык<span class="letter">e</span></div>
							<div class="card-article__bottom">
								<div class="card-article__text">О блогерах, «войсах» <br>и растущей грамотности.</div>
							</div>
							<a href="javascript:void(0)" class="card-article__link">Читать статью</a>
						</div>
					</div>
				</div>
				<div class="slider-button slider-button-prev" @click="slidePrev"></div>
				<div class="slider-button slider-button-next" @click="slideNext"></div>
			</div>
			<div class="experts__elements">
				<div class="experts__element-mob-1"></div>
				<div class="experts__element-mob-2"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';
	import { gsap } from 'gsap';
	import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
	gsap.registerPlugin(ScrollTrigger);
	import Swiper from 'swiper';
	import 'swiper/swiper-bundle.css';

	export default {
		name: 'Experts',
		data() {
			return {
				title: false,
				readyAnim: false,
				drawline: false,
				slider: null,
			}
		},

		mounted() {
			this.slider = new Swiper('.swiper-articles', {
				direction: 'horizontal',
				slidesPerView: 1.5,
				loop: true,
				centeredSlides: true,
			});
			ScrollTrigger.create({
				trigger: "#exp",
				start: "bottom bottom",
				once: true,
				scrub: true,
				onToggle: () => {
						this.animate()
					setTimeout(() => {
						this.readyAnim = true;
					}, 400) 
					setTimeout(() => {
						this.drawline = true;
					}, 1400) 
				},
			});
		},

		methods: {
			openArticle(id, url) {
				this.$gtag.event('click', {event_category: 'link','event_label': 'article_'+id})
				if (url !== 'none') {
					window.open(url, '_blank').focus();
				}
			},
			slideNext() {
        this.slider.slideNext();
      },
      slidePrev() {
        this.slider.slidePrev();
      },
			setCursor(status) {
				this.$store.dispatch('setCursor', status)
			},
			animate() {
				if (!this.title) {
					anime.timeline({loop: false})
						.add({
							targets: '.ml10 .lll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 60 * i
						});
					this.title = true;
				}
			}
		}
	}
</script>

<style lang="sass">
	@media screen and (max-width: 1000px)
		.path4
			stroke-dasharray: 2000
			stroke-dashoffset: 2000
			&.animated
				animation: dash 1s linear forwards
		.hidden
			visibility: hidden !important
			opacity: 0 !important
		.ml10
			position: relative	
			display: block
			margin-bottom: -1vw
		.ml10 .text-wrapper 
			position: relative
			display: inline-block
			overflow: hidden
		
		.ml10 .lll
			transform-origin: 0 100%
			display: inline-block
		.lll
			position: relative
			&--space
				width: vwm(14px)
				display: inline-block
				height: 100%
		.experts__title
			font-size: vwm(30px)
			line-height: vwm(45px)
			letter-spacing: 2.8px
			margin-left: vwm(4px)
			margin-bottom: vwm(25px)
		.experts__title span.letter
			font-size: vwm(36px)
		.experts
			padding-top: vwm(78px)
			.swiper
				width: calc(100% + 30px)
				margin-left: vwm(-15px)
				position: relative
			.slider-button
				top: vwm(-65px)
				&-prev
					left: vwm(15px)
				&-next
					right: vwm(15px)
		.experts__title-el
			width: vwm(196px)
			height: vwm(56px)
			top: -2.2vw
			left: 28.66667vw
		.experts__text
			font-size: vwm(18px)
			line-height: vwm(26px)
			letter-spacing: 0.6px
		.experts__widget-text
			font-size: vwm(16px)
			line-height: vwm(15px)
			text-align: center
			margin-bottom: vwm(23px)
		.experts__widget
			margin-top: vwm(49px)
		.experts__element-mob-1
			background-image: url(../../assets/img/experts-el-mob-1.svg)
			width: vwm(6px)
			height: vwm(5px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: vwm(-37px)
			right: vwm(6px)
		.experts__element-mob-2
			background-image: url(../../assets/img/experts-el-mob-2.svg)
			width: vwm(59px)
			height: vwm(18px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: vwm(80px)
			left: 0

		.card-article
			width: vwm(230px)
			min-width: vwm(230px)
			min-height: vwm(292px)
			border-radius: vwm(10px)
			padding: vwm(21px) vwm(14px) vwm(19px) vwm(14px)
			margin: auto
		.card-article:after
			top: vwm(5px)
			left: vwm(-5px)
			border-radius: vwm(10px)
		.card-article__title
			font-size: vwm(20px)
			line-height: vwm(29px)
			letter-spacing: 1px
		.card-article__title span.letter
			font-size: vwm(24px)
		.card-article__title span.line:after
			height: vwm(6px)
			left: 1px
			top: vwm(25px)
		.card-article__text
			font-size: vwm(18px)
			line-height: vwm(18px)
		.card-article__link
			font-size: vwm(20px)
			color: #000
			text-decoration: underline
			position: absolute
			bottom: vwm(-41px)
			left: 0
			right: 0
			margin: auto
			text-align: center

		.slider-button
			width: vwm(40px)
			height: vwm(40px)
			border-radius: 50%
			background: #EBFF73
			position: absolute
			background-image: url(../../assets/img/swiper-arrow.svg)
			background-size: vwm(22px)
			background-repeat: no-repeat
			background-position: 35% 50%
			&-prev
				transform: rotate(180deg)
</style>