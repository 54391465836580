<template>
	<div class="page" id="scene">
		<div class="container">
			<div :class="`ball ball--${$store.state.cursor}`"></div>
			<Lead />
			<Hero />
			<Interactive />
			<Why />
			<Experts />
			<Footer />
		</div>
	</div>
</template>

<script>
	import AOS from 'aos';
	import Rellax from 'rellax';
	import gsap from 'gsap';
	import 'aos/dist/aos.css';
	import { TweenLite } from 'gsap/all';
	import Lead from '@/components/Lead.vue'
	import Hero from '@/components/Hero.vue'
	import Interactive from '@/components/Interactive.vue'
	import Why from '@/components/Why.vue'
	import Experts from '@/components/Experts.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		name: 'Main',
		components: {
			Lead,
			Hero,
			Interactive,
			Why,
			Experts,
			Footer,
		},
		mounted() {
			new Rellax('.rellax');
			AOS.init();
			window.addEventListener("mousemove", this.parallaxEvent);
			gsap.set(".ball", {xPercent: 0, yPercent: 0});

			var ball = document.querySelector(".ball");
			var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
			var mouse = { x: pos.x, y: pos.y };
			var speed = 0.1;

			var fpms = 60 / 1000;

			var xSet = gsap.quickSetter(ball, "x", "px");
			var ySet = gsap.quickSetter(ball, "y", "px");

			window.addEventListener("mousemove", e => {
				mouse.x = e.x+5;
				mouse.y = e.y+5;
			});

			gsap.ticker.add((time, deltaTime) => {

				var delta = deltaTime * fpms;
				var dt = 1.0 - Math.pow(1.0 - speed, delta);

				pos.x += (mouse.x - pos.x) * dt;
				pos.y += (mouse.y - pos.y) * dt;
				xSet(pos.x);
				ySet(pos.y);
			});
		},
		methods: {
			parallaxEvent() {
				var pageX =  event.pageX - (document.getElementById('scene').getBoundingClientRect().width * 0.5);
				var pageY =  event.pageY - (document.getElementById('scene').getBoundingClientRect().height * 0.5);
				var i = 0;
				let parallaxItems = document.getElementsByClassName('parallax');
				for (i = 0; i < parallaxItems.length; i++) {
						var item = parallaxItems[i];
						var speedX = item.getAttribute("data-speed-x") || 30;
						var speedY = item.getAttribute("data-speed-y") || 30;
						TweenLite.set(item, {
								x: (item.offsetLeft + pageX * speedX ) * 0.007,
								y: (item.offsetTop + pageY * speedY) * 0.002
						});
				}
			},
		}
	}
</script>

<style lang="sass">
	.ball
		width: vw(20px)
		height: vw(20px)
		position: fixed
		margin-left: vw(-10px)
		margin-top: vw(-10px)
		top: 0
		left: 0
		z-index: 1000
		border: 1px solid #EBFF73
		border-radius: 50%
		display: block
		background-position: 50% 50%
		transition: transform 10ms, background 400ms, width 200ms, height 200ms
		pointer-events: none
		background-size: 0px
		&--default
			&:after
				content: ''
				display: block
				width: 2px
				height: 2px
				background-color: #EBFF73
				top: 0
				left: 0
				right: 0
				bottom: 0
				margin: auto
				position: absolute
		&--open
			width: vw(40px)
			height: vw(40px)
			background-color: rgba(255,255,255,0.5)
			border-color: rgba(255,255,255,0.5)
		&--read
			width: vw(80px)
			height: vw(80px)
			background-color: #EBFF73
			border-color: #EBFF73
			background-image: url(../assets/img/read.svg)
			background-repeat: no-repeat
			background-size: 100%
		&--readsoon
			width: vw(80px)
			height: vw(80px)
			background-color: #EBFF73
			border-color: #EBFF73
			background-image: url(../assets/img/soon.svg)
			background-repeat: no-repeat
			background-size: 100%
		&--readpink
			width: vw(80px)
			height: vw(80px)
			background-color: #FCC8E7
			border-color: #FCC8E7
			background-image: url(../assets/img/read.svg)
			background-repeat: no-repeat
			background-size: 100%
		&--readpinksoon
			width: vw(80px)
			height: vw(80px)
			background-color: #FCC8E7
			border-color: #FCC8E7
			background-image: url(../assets/img/soon.svg)
			background-repeat: no-repeat
			background-size: 100%
		@media screen and (max-width: 1000px)
			display: none
</style>