<template>
	<div class="hero">
		<div class="hero__content">
			<div class="hero__pushkin">
				<div class="hero__pushkin-bg parallax"  data-speed-x="-5" data-speed-y="-4"></div>
				<div class="hero__pushkin-head">
					<div class="hero__pushkin-apple js-eye-left hero__pushkin-apple--1"></div>
					<div class="hero__pushkin-apple js-eye-right hero__pushkin-apple--2"></div>
				</div>
				<div class="hero__pushkin-el-1 parallax" data-speed-x="-1" data-speed-y="2"></div>
				<div class="hero__pushkin-el-2 parallax" data-speed-x="-4" data-speed-y="4"></div>
				<div class="hero__pushkin-el-3 parallax" data-speed-x="2" data-speed-y="18"></div>
				<div class="hero__pushkin-el-4 parallax" data-speed-x="6" data-speed-y="-10"></div>
				<div class="hero__pushkin-el-5 parallax" data-speed-x="-11" data-speed-y="1"></div>
				<div class="hero__pushkin-el-6 parallax" data-speed-x="-4" data-speed-y="-5"></div>
				<div class="hero__pushkin-el-7"></div>
				<div class="hero__pushkin-el-8"></div>
				<div class="hero__pushkin-el-9"></div>
				<div class="hero__pushkin-el-10"></div>
			</div>
			<div class="hero__right">
				<div class="hero__text hero__text--desk" data-aos="fade-up" data-aos-delay="2500" data-aos-duration="800" data-aos-offest="500">
					Если раньше язык ощутимо менялся за десятки и сотни лет, то сейчас этот процесс стал гораздо быстрее. В 2022 году мы уже говорим <br>не совсем на том языке, на котором общались 20 лет назад. Три <br>года назад мы понятия не имели, что такое ковид и социальная дистанция. А если сейчас вы напишете кому-то «превед», вас, скорее всего, спросят, не переутомились ли вы на работе. Мы решили узнать у экспертов, какие же процессы в русском языке сейчас происходят <br>и как меняется наше общение. Но для начала давайте проверим, насколько вы владеете современной лексикой.
				</div>
				<div class="hero__text hero__text--mob" data-aos="fade-up" data-aos-delay="0" data-aos-duration="800" data-aos-offest="500">
					Если раньше ощутимые перемены в языке происходили за десятки и сотни лет, <br>то сейчас это процесс происходит еще быстрее. В 2022 году мы уже говорим <br>не совсем на том языке, на котором общались 20 лет назад. Три года назад мы понятия не имели, что такое ковид <br>и социальная дистанция. А если сейчас вы напишете кому-то “превед”, вас, скорее всего, спросят, не переутомились ли вы <br>на работе. Мы решили узнать у экспертов, какие же процессы в русском языке сейчас происходят и как меняется наше общение. 
				</div>
				<div data-aos="fade-up">
					<div class="hero__quote rellax" data-rellax-speed="1" data-rellax-percentage="0.5">“Язык — это история народа. Язык — это путь цивилизации <br class="mobile-only">и культуры”.</div>
				</div>
			</div>
			<div class="hero__elements">
				<div class="hero__element-1 parallax" data-speed-x="-1" data-speed-y="2"></div>
				<div class="hero__element-2 parallax" data-speed-x="-4" data-speed-y="4"></div>
				<div class="hero__element-3 parallax" data-speed-x="-11" data-speed-y="1"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Hero',
		mounted() {
			const eye1 = document.querySelector('.js-eye-left');
			const eye2 = document.querySelector('.js-eye-right')
			window.addEventListener('mousemove', (evt) => {

					let h = document.documentElement, 
							b = document.body,
							st = 'scrollTop',
							sh = 'scrollHeight';

					let percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;

					if (percent < 20) {
						const x = -(window.innerWidth / 5 - evt.pageX) / 2890;
						const y = -(window.innerHeight / 5	 - evt.pageY) / 2890;
						eye1.style.transform = `translateY(${y}vw) translateX(${x}vw)`;
						eye2.style.transform = `translateY(${y}vw) translateX(${x}vw)`;
					} else {
						eye1.style.transform = `translateY(0.1vw) translateX(0.1vw)`;
						eye2.style.transform = `translateY(0.1vw) translateX(0.1vw)`;
					}
			});
		}
	}
</script>

<style lang="sass">
	.rellax
		transition: all 300ms
	.hero
		&__content
			display: flex
			justify-content: space-between
			position: relative
		&__pushkin
			width: vw(588px)
			height: vw(565px)
			margin-top: vw(-34px)
			margin-left: vw(-68px)
			position: relative
			&-bg
				background-image: url(../assets/img/pushkin-bg.png)
				background-size: contain
				background-repeat: no-repeat
				width: 100%
				height: 100%
				position: absolute
			&-head
				background-image: url(../assets/img/pushkin.png)
				width: vw(420px)
				height: vw(452px)
				background-size: contain
				background-repeat: no-repeat
				margin-left: vw(82px)
				margin-top: vw(57px)
				position: relative
			&-apple
				background: #000
				border-radius: 50%
				position: absolute
				transition: all 500ms
				&--1
					width: vw(10px)
					height: vw(10px)
					top: vw(219px)
					left: vw(212px)
				&--2
					width: vw(9px)
					height: vw(9px)
					top: vw(221px)
					right: vw(102px)
			&-el
				&-1
					background-image: url(../assets/img/pushkin-el-1.svg)
					width: vw(55px)
					height: vw(48px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(24px)
					left: vw(180px)
				&-2
					background-image: url(../assets/img/pushkin-el-2.svg)
					width: vw(73px)
					height: vw(34px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(81px)
					left: vw(61px)
				&-3
					background-image: url(../assets/img/pushkin-el-3.svg)
					width: vw(8px)
					height: vw(6px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(99px)
					right: vw(106px)
				&-4
					background-image: url(../assets/img/pushkin-el-4.svg)
					width: vw(47px)
					height: vw(47px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(275px)
					right: vw(6px)
				&-5
					background-image: url(../assets/img/pushkin-el-5.svg)
					width: vw(20px)
					height: vw(18px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(372px)
					left: vw(123px)
				&-6
					background-image: url(../assets/img/pushkin-el-6.svg)
					width: vw(72px)
					height: vw(47px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(419px)
					left: vw(108px)
				&-7
					background-image: url(../assets/img/pushkin-el-7.svg)
					width: vw(84px)
					height: vw(26px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(490px)
					left: vw(154px)
				&-8
					background-image: url(../assets/img/pushkin-el-8.svg)
					width: vw(10px)
					height: vw(9px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(367px)
					right: vw(96px)
				&-9
					background-image: url(../assets/img/pushkin-el-9.svg)
					width: vw(61px)
					height: vw(61px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(389px)
					right: vw(38px)
				&-10
					background-image: url(../assets/img/pushkin-el-10.svg)
					width: vw(8px)
					height: vw(6px)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: vw(480px)
					right: vw(125px)
		&__right
			margin-top: vw(105px)
		&__text
			font-size: vw(18px)
			line-height: vw(27px)
			color: #000
			width: vw(562px)
			margin-right: vw(79px)
			position: relative
			z-index: 10
			&--mob
				display: none
		&__quote
			font-size: vw(43px)
			line-height: vw(38px)
			font-family: 'Gogol'
			transform: rotate(-5deg)
			color: #EBFF73
			width: vw(554px)
			margin-top: vw(36px)
			margin-left: auto
			margin-right: vw(-79px)
			br
				&.mobile-only
					display: none
		&__element
			&-1
				background-image: url(../assets/img/hero-el-1.svg)
				width: vw(61px)
				height: vw(61px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				right: vw(166px)
				top: vw(-11px)
			&-2
				background-image: url(../assets/img/hero-el-2.svg)
				width: vw(15px)
				height: vw(13px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				right: vw(-7px)
				top: vw(194px)
			&-3
				background-image: url(../assets/img/hero-el-3.svg)
				width: vw(63px)
				height: vw(30px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				right: vw(-26px)
				top: vw(540px)

	@media screen and (max-width: 1000px)
		.hero__content
			flex-direction: column
		.hero__pushkin
			margin-top: vwm(-19px)
			width: vwm(434px)
			height: vwm(425px)
			margin-left: vwm(-51px)
		.hero__pushkin-head
			width: vwm(310px)
			height: vwm(334px)
			margin-top: vwm(42px)
			margin-left: vwm(59px)
		.hero__text
			width: 100%
			font-size: vwm(18px)
			line-height: vwm(24px)
			margin-right: 0
			&--desk
				display: none
			&--mob
				display: block
		.hero__right
			margin-top: vwm(-10px)
		.hero__pushkin-el-1
			background-image: url(../assets/img/pushkin-el-10.svg)
			width: vwm(8px)
			height: vwm(6px)
			top: vwm(239px)
			left: vwm(47px)
		.hero__pushkin-el-2
			width: vwm(53px)
			height: vwm(25px)
			top: vwm(42px)
			left: vwm(329px)
		.hero__pushkin-el-3
			width: vwm(8px)
			height: vwm(6px)
			top: vwm(94px)
			right: vwm(73px)
		.hero__pushkin-el-4
			width: vwm(39px)
			height: vwm(39px)
			top: vwm(201px)
			right: vwm(37px)
		.hero__pushkin-el-5
			width: vwm(15px)
			height: vwm(13px)
			top: vwm(255px)
			left: vwm(96px)
		.hero__pushkin-el-6
			width: vwm(49px)
			height: vwm(32px)
			top: vwm(316px)
			left: vwm(72px)
		.hero__pushkin-el-7
			width: vwm(59px)
			height: vwm(18px)
			top: vwm(358px)
			left: vwm(114px)
		.hero__pushkin-el-8
			width: vwm(10px)
			height: vwm(9px)
			top: vwm(257px)
			right: vwm(83px)
		.hero__pushkin-el-9
			width: vwm(42px)
			height: vwm(42px)
			top: vwm(265px)
			right: vwm(51px)
		.hero__pushkin-el-10
			width: vwm(8px)
			height: vwm(6px)
			top: vwm(337px)
			right: vwm(105px)
		.hero__quote
			font-size: vwm(42px)
			line-height: vwm(37px)
			margin-right: 0
			margin-left: vwm(12px)
			width: 100%
			margin-top: vwm(43px)
			br
				&.mobile-only
					display: block
		.hero__element-2
			width: vwm(12px)
			height: vwm(11px)
			top: vwm(725px)
			right: vwm(212px)
			transform: rotate(180deg)
		.hero__element-3
			width: vwm(62px)
			height: vwm(65px)
			background-image: url(../assets/img/hero-el-mob-1.svg)
			top: vwm(448px)
			right: vwm(-6px)
		.hero__element-1
			width: vwm(42px)
			height: vwm(42px)
			margin-top: vwm(715px)
			right: vwm(8px)
			transform: scale(-1, 1)
		.hero__pushkin-apple--1
			width: vwm(9px)
			height: vwm(9px)
			left: vwm(156px)
			top: vwm(161px)
		.hero__pushkin-apple--2
			width: vwm(8px)
			height: vwm(8px)
			right: vwm(74px)
			top: vwm(162px)
</style>