<template>
	<div class="page">
		<div class="container">
			<Burger />
			<Lead />
			<Hero />
			<Experts />
			<Interactive />
			<Why />
			<Footer />
		</div>
	</div>
</template>

<script>
	import AOS from 'aos';
	import Rellax from 'rellax';
	import Burger from '@/components/mobile/Burger.vue'
	import Lead from '@/components/Lead.vue'
	import Hero from '@/components/Hero.vue'
	import Experts from '@/components/mobile/Experts.vue'
	import Interactive from '@/components/mobile/Interactive.vue'
	import Why from '@/components/mobile/Why.vue'
	import Footer from '@/components/mobile/Footer.vue'
	export default {
		name: 'Main',
		components: {
			Burger,
			Lead,
			Hero,
			Experts,
			Interactive,
			Why,
			Footer,
		},
		mounted() {
			new Rellax('.rellax');
			AOS.init();
		},
	}
</script>