import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router.js'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueGtag, {
  config: { id: "UA-90021243-25" }
});

const store = new Vuex.Store({
	state: {
		cursor: 'default'
	},
	actions: {
		setCursor({commit}, status) {
			commit('set_cursor', status)
		}
	},
	mutations: {
		set_cursor (state, status) {
			state.cursor = status;
		}
	}
});

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
