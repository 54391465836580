<template>
	<div class="mobile-menu">
		<div class="burger" :class="{'burger--active': BurgerOpen}" @click="BurgerOpen = !BurgerOpen">
			<span></span>
			<span></span>
		</div>
		<div class="burger__menu" v-if="BurgerOpen">
			<div class="burger__menu-item">
				<a href="javascript:void(0)" class="burger__menu-link">Как <span class="line-1">феминитивы</span> <br>становятся новой нормой</a>
			</div>
			<div class="burger__menu-item">
				<a href="javascript:void(0)" class="burger__menu-link"><span class="line-2">Англицизмы:</span> хорошо это <br>или нет?</a>
			</div>
			<div class="burger__menu-item">
				<a href="javascript:void(0)" class="burger__menu-link">Как мы <span class="line-3">общаемся</span> <br>в соцсетях </a>
			</div>
			<div class="burger__bottom">
				<div class="burger__bottom-title">Поделиться проектом:</div>
				<div class="burger__social">
					<div class="burger__social-item">
						<a href="javascript:void(0)" class="burger__social-link burger__social-link--vk"></a>
					</div>
					<div class="burger__social-item">
						<a href="javascript:void(0)" class="burger__social-link burger__social-link--ok"></a>
					</div>
					<div class="burger__social-item">
						<a href="javascript:void(0)" class="burger__social-link burger__social-link--tw"></a>
					</div>
					<div class="burger__social-item">
						<a href="javascript:void(0)" class="burger__social-link burger__social-link--tg"></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Burger',
		data() {
			return {
				BurgerOpen: false,
			}
		}
	}
</script>

<style lang="sass">
	.mobile-menu
		padding-top: vwm(25px)
	.burger
		display: block
		width: vwm(70px)
		margin: auto
		cursor: pointer
		z-index: 200
		position: relative
		span
			width: 100%
			height: 2px
			background: #000
			display: block
			margin-bottom: vwm(14px)
			&:last-child
				margin-bottom: 0
		&--active
			margin-top: vwm(17px)
			width: vwm(52px)
			span
				&:nth-child(1)
					transform: rotate(45deg)
				&:nth-child(2)
					transform: rotate(-45deg)
					margin-top: vwm(-14px)
		&__menu
			position: fixed
			top: 0
			left: 0
			background: #FCC8E7
			width: 100vw
			height: 100vh
			z-index: 100
			padding-top: vwm(98px)
			padding-bottom: vwm(80px)
			display: flex
			flex-direction: column
			&-item
				text-align: center
				margin-bottom: vwm(25px)
			&-link
				font-size: vwm(20px)
				line-height: vwm(26px)
				color: #000
				text-transform: uppercase
				font-weight: 500
				text-decoration: none
				letter-spacing: 0.4px
				span
					&.line-1
						position: relative
						&:after
							content: ''
							background-image: url(../../assets/img/line-1.svg)
							width: vwm(154px)
							height: vwm(9px)
							background-size: contain
							background-repeat: no-repeat
							position: absolute
							display: block
							left: 0
							top: vwm(24px)
					&.line-2
						position: relative
						&:after
							content: ''
							background-image: url(../../assets/img/line-2.svg)
							width: vwm(152px)
							height: vwm(8px)
							background-size: contain
							background-repeat: no-repeat
							position: absolute
							display: block
							left: 0
							top: vwm(24px)
					&.line-3
						position: relative
						&:after
							content: ''
							background-image: url(../../assets/img/line-3.svg)
							width: vwm(126px)
							height: vwm(13px)
							background-size: contain
							background-repeat: no-repeat
							position: absolute
							display: block
							left: 0
							top: vwm(24px)
		&__bottom
			margin-top: auto
			&-title
				text-align: center
				font-size: 16px
				color: #000
				letter-spacing: 0.5px
				margin-bottom: vwm(17px)
		&__social
			display: flex
			justify-content: center
			&-item
				margin-left: vwm(10px)
				margin-right: vwm(10px)
			&-link
				width: vwm(42px)
				height: vwm(42px)
				background: #EBFF73
				border: 1px solid #000
				border-radius: 50%
				display: block
				&--vk
					background-image: url(../../assets/img/social/vk.svg)
					background-size: vwm(19px)
					background-repeat: no-repeat
					background-position: 53% 53%
				&--ok
					background-image: url(../../assets/img/social/ok.svg)
					background-size: vwm(11px)
					background-repeat: no-repeat
					background-position: 53% 53%
				&--tw
					background-image: url(../../assets/img/social/tw.svg)
					background-size: vwm(19px)
					background-repeat: no-repeat
					background-position: 53% 53%
				&--tg
					background-image: url(../../assets/img/social/tg.svg)
					background-size: vwm(20px)
					background-repeat: no-repeat
					background-position: 53% 53%
</style>