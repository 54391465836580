<template>
	<div class="lead">
		<div class="lead__content">
			<div class="lead__title ml7" :class="{'invisible': !showLead}"><span class="foreground text-wrapper"><span class="l">Н<span class="background">Н</span></span><span class="l">а<span class="background">а</span></span><span class="l l--space"> </span><span class="l">я<span class="background">я</span></span><span class="l">з<span class="background">з</span></span><span class="l">ы<span class="background">ы</span></span><span class="l">к<span class="background">к</span></span><span class="letter l">e<span class="background">е</span></span><span class="l l--space"> </span><span class="l">п<span class="background">п</span></span><span class="letter l">e<span class="background">е</span></span><span class="l">р<span class="background">р</span></span><span class="letter l">e<span class="background">е</span></span><span class="l">м<span class="background">м</span></span><span class="letter l">e<span class="background">е</span></span><span class="l">н<span class="background">н</span></span></span></div>
			<div class="lead__text" data-aos="fade-up" data-aos-duration="900" data-aos-delay="1700">
				<span>
					что происходит с русским языком в эпоху <br>зумеров, стремительного развития технологий <br>и новой <br class="mobile-only">этики.
					<div class="lead__text-el">
						<svg width="100%" height="100%" viewBox="0 0 264 50" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M254.694 1.1675C214.243 1.1675 173.48 0.463063 133.154 1.94346C100.988 3.12432 68.6345 3.4227 38.9172 9.27201C20.0616 12.9834 3.24687 15.0323 1.144 25.2224C-1.22626 36.7082 26.1415 42.5378 48.018 44.6215C89.6168 48.5837 136.028 50.6491 178.365 47.3805C199.358 45.7597 221.769 44.6979 239.624 39.0173C265.021 30.9369 275.368 15.9813 241.385 8.49604C214.531 2.58103 179.452 5.36268 150.867 5.86639C125.395 6.31523 101.731 11.3419 76.7882 12.807C59.5895 13.8172 42.7033 18.4282 31.9693 24.4464C22.0981 29.9809 35.0968 38.456 46.8437 41.0434C60.4286 44.0357 77.3401 44.282 92.054 45.0526C113.045 46.1518 135.08 47.0436 156.249 46.9494C179.076 46.8478 212.463 46.6371 230.425 39.0173C242.262 33.9958 254.694 29.8915 254.694 22.1185C254.694 11.5991 234.535 8.29896 217.704 1.94346" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path1"/>
						</svg>
					</div>
				</span>
			</div>
			<div class="lead__elements">
				<div class="lead__element-1 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="lead__element-2 parallax" data-speed-x="-10" data-speed-y="-20"></div>
				<div class="lead__element-3 parallax" data-speed-x="5" data-speed-y="-4">
					<span>А</span>
					<span>а</span>
					<span>Б</span>
					<span>б</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';

	export default {
		name: 'Lead',
		data() {
			return {
				showLead: false,
			}
		},
		mounted() {
			setTimeout(() => {
				if (window.innerWidth > 1000) {
					anime.timeline({loop: false})
						.add({
							targets: '.ml7 .l',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 60 * i
						});
					} else {
						anime.timeline({loop: false})
							.add({
								targets: '.ml7 .l',
								translateY: ["40vw", 0],
								translateX: ["0", 0],
								translateZ: 0,
								rotateZ: [20, 0],
								duration: 1500,
								easing: "easeOutExpo",
								delay: (el, i) => 60 * i
							});
					}
			}, 1100)
			setTimeout(() => {
				this.showLead = true;
			}, 1200)
		}
	}
</script>

<style lang="sass">
	.path1
		stroke-dasharray: 2000
		stroke-dashoffset: 2000
		animation: dash 1s linear forwards
		animation-delay: 3s
	@keyframes dash 
		to 
			stroke-dashoffset: 0
	.invisible
		visibility: hidden !important
		opacity: 0 !important
	.ml7
		position: relative	
	.ml7 .text-wrapper 
		position: relative
		display: inline-block
		overflow: hidden
		padding-left: vw(10px)
		margin-left: vw(-10px)
	
	.ml7 .l
		transform-origin: 0 100%
		display: inline-block
	.l
		position: relative
		&--space
			width: vw(30px)
			display: inline-block
			height: 100%
	.lead
		padding-top: vw(70px)
		&__title
			font-family: 'Mutabor'
			font-size: vw(80px)
			letter-spacing: vw(11.1px)
			text-transform: uppercase
			text-align: center
			text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000
			margin-bottom: vw(50px)
			margin-left: vw(10px)
			span
				&.foreground
					color: #EBFF73
					position: relative
				&.background
					color: #000
					position: absolute
					top: vw(4px)
					left: vw(-4px)
					z-index: -1
				&.letter
					font-family: 'Open Sans'
					font-size: vw(97px)
					.background
						top: vw(4px)
		&__text
			font-size: vw(24px)
			line-height: vw(35px)
			letter-spacing: 0.05em
			text-transform: uppercase
			color: #000
			font-weight: 500
			text-align: center
			br
				&.mobile-only
					display: none
			span
				position: relative
			&-el
				width: vw(278px)
				height: vw(50px)
				position: absolute
				top: vw(-12px)
				left: vw(-30px)
				z-index: -1
		&__content
			position: relative
		&__element
			&-1
				background-image: url(../assets/img/lead-el-1.svg)
				width: vw(14px)
				height: vw(13px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(-11px)
				left: vw(33px)
			&-2
				background-image: url(../assets/img/lead-el-2.svg)
				width: vw(100px)
				height: vw(31px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(-5px)
				right: vw(8px)
			&-3
				font-family: 'Pushkin'
				font-size: vw(50px)
				position: absolute
				color: #EBFF73
				transform: rotate(-5deg)
				position: absolute
				left: vw(-44px)
				top: vw(174px)

	@media screen and (max-width: 1000px)
		.lead
			padding-top: vwm(41px)
		.lead__title
			font-size: vwm(54px)
			line-height: vwm(68px)
			margin-left: vwm(9px)
			letter-spacing: vwm(5.5px)
			margin-bottom: vwm(28px)
			text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000
		.lead__title span.letter
			font-size: vwm(66px)
		.lead__title span.background
			top: -1px
			left: vwm(-3px)
		.lead__element-2
			display: none
		.lead__element-1
			display: none
		.lead__text
			font-size: vwm(18px)
			line-height: vwm(25px)
			letter-spacing: 0.5px
			br
				display: none
				&.mobile-only
					display: block
		.lead__text-el
			width: vwm(190px)
			height: vwm(36px)
			top: vwm(-7px)
			left: vwm(-11px)
		.lead__element-3
			font-size: vwm(40px)
			top: vwm(298px)
			left: vwm(-2px)	
</style>