<template>
	<div class="experts">
		<div class="experts__content">
			<div class="experts__title" id="exp">
					<div class="experts__title-el">
						<svg width="100%" height="100%" viewBox="0 0 242 69" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M125.472 0.999878C94.1509 2.68121 67.0095 3.10605 38.0737 11.4344C19.7141 16.7187 3.3416 19.636 1.29405 34.1448C-1.01388 50.4986 10.3711 58.7988 31.6722 61.7657C72.1769 67.4072 117.368 70.3479 158.591 65.6939C179.032 63.3862 200.854 61.8744 218.239 53.7863C242.968 42.2813 253.043 20.9873 219.954 10.3296C193.806 1.90767 159.65 5.86822 131.816 6.58542C107.014 7.22449 83.9726 14.3815 59.6858 16.4675C42.9394 17.9059 26.4973 24.4711 16.0456 33.04C6.4341 40.9201 19.0909 52.9871 30.5288 56.6711C43.7564 60.9316 60.2231 61.2823 74.5501 62.3794C94.9885 63.9446 116.445 65.2143 137.057 65.0802C159.284 64.9355 191.792 64.6356 209.282 53.7863C220.808 46.6366 232.912 40.7929 232.912 29.7255" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path4" :class="{'animated': drawline}" />
						</svg>
					</div>
				<span class="wrapper" :class="{'hidden': !readyAnim}">
					<span class="ml10">
						<span class="text-wrapper">
							<span class="lll">А</span>
							<span class="lll lll--space"> </span>
							<span class="lll">ч</span>
							<span class="lll">т</span>
							<span class="lll">о</span>
							<span class="lll lll--space"> </span>
							<span class="lll">д</span>
							<span class="lll">у</span>
							<span class="lll">м</span>
							<span class="lll">а</span>
							<span class="lll">ю</span>
							<span class="lll">т</span>
							<span class="lll lll--space"> </span>
							<span class="lll">э</span>
							<span class="lll">к</span>
							<span class="lll">с</span>
							<span class="lll">п</span>
							<span class="lll letter">e</span>
							<span class="lll">р</span>
							<span class="lll">т</span>
							<span class="lll">ы</span>
							<span class="lll">?</span>
						</span>
					</span>
				</span>
			</div>
			<div class="experts__text" data-aos="fade-up" data-aos-delay="2000" data-aos-duration="900">подробнее о том, как неологизмы, заимствования <br>из других языков и даже мемы влияют на наше <br>общение, мы поговорили с экспертами.</div>
			<div class="experts__widget" data-aos="fade-up" data-aos-delay="2000" data-aos-duration="900">
				<div class="experts__widget-icon"></div>
				<div class="experts__widget-text">кликайте, чтобы прочитать статью</div>
			</div>
			<div class="experts__list">
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2000" data-aos-offset="-400">
					<div class="card-article" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(1, 'https://www.maximonline.ru/guide/kak-memy-izmenili-russkii-yazyk-id729825/')">
						<div class="card-article__title">Как м<span class="letter">e</span>мы <span class="line">изм<span class="letter">e</span>нили</span> русский язык</div>
						<div class="card-article__bottom">
							<div class="card-article__description">Смешные картинки, вирусные видео и русский язык.</div>
							<div class="card-article__text">Полина Масалыгина,	автор блога «О русском языке без снобизма»</div>
						</div>
					</div>
				</div>
				<div class="experts__list-column">
					<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2300" data-aos-offset="-400">
						<div class="card-article card-article--small" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(2, 'https://www.maximonline.ru/guide/kak-obshatsya-s-zumerami-podkasty-laiki-i-novye-platformy-id729837/')">
							<div class="card-article__title">Как <br>общаться <br>с зум<span class="letter">e</span>рами?</div>
							<div class="card-article__info">
								<div class="card-article__description">Как общаться с зумeрами: лайки, подкасты и новыe платформы.</div>
								<div class="card-article__text">Алиса Карпенко, редактор ELLEgirl.ru</div>
							</div>
						</div>
					</div>
					<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2300" data-aos-offset="-400">
						<div class="card-article card-article--small card-article--yellow" @mouseenter="setCursor('readpink')" @mouseleave="setCursor('default')" @click="openArticle(3, 'https://www.maximonline.ru/guide/anglicizmy-mozhno-yuzat-ili-luchshe-skipat-id727904/')">
							<div class="card-article__title">Англицизмы: хорошо это или н<span class="letter">e</span>т?</div>
							<div class="card-article__info">
								<div class="card-article__description">Почему мы заменяем <br>русские слова английскими <br>и нормально ли это?</div>
								<div class="card-article__text">Яна Войцишевская: директор центра иностранных языков, магистр филологии</div>
							</div>
						</div>
					</div>
				</div>
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2800" data-aos-offset="-400">
					<div class="card-article" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(4, 'https://www.maximonline.ru/guide/kak-feminitivy-stanovyatsya-novoi-normoi-i-stanovyatsya-li-id727900/')">
						<div class="card-article__title">Как <span class="line">ф<span class="letter">e</span>минитивы</span> становятся новой нормой</div>
						<div class="card-article__bottom">
							<div class="card-article__description">Разбираемся в «редактрисах», «продавицах» и «логистках».</div>
							<div class="card-article__text">Ирина Фуфаева: филолог, автор книги «Как называются женщины»</div>
						</div>
					</div>
				</div>
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2900" data-aos-offset="-400">
					<div class="card-article card-article--yellow" @mouseenter="setCursor('readpink')" @mouseleave="setCursor('default')" @click="openArticle(5, 'https://www.maximonline.ru/guide/kakim-yazykom-sovremennye-media-govoryat-s-chitatelyami-id729832/')">
						<div class="card-article__title">Каким языком совр<span class="letter">e</span>м<span class="letter">e</span>нны<span class="letter">e</span> м<span class="letter">e</span>диа говорят с читат<span class="letter">e</span>лями</div>
						<div class="card-article__bottom">
							<div class="card-article__description">О том, как русский язык «перемелет» новые медиа.</div>
							<div class="card-article__text">Мария Пименова и Александр Акулиничев, редакторы MAXIM <br>и Psychologies</div>
						</div>
					</div>
				</div>
				<div class="experts__list-column">
					<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2300" data-aos-offset="-400">
						<div class="card-article card-article--small" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(6, 'https://www.maximonline.ru/guide/ty-bolshoi-molodec-no-ya-nichego-ne-ponyal-kak-na-yazyk-vliyayut-tekhnologii-id732118/')">
							<div class="card-article__title">как на язык <br>влияют <br>т<span class="letter">e</span>хнологии</div>
							<div class="card-article__info">
								<div class="card-article__description">О блогерах, «войсах» <br>и растущей грамотности.</div>
								<div class="card-article__text">Максим Буланов, тьютор, эксперт образовательных практик</div>
							</div>
						</div>
					</div>
					<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2300" data-aos-offset="-400">
						<div class="card-article card-article--small card-article--yellow" @mouseenter="setCursor('readpink')" @mouseleave="setCursor('default')" @click="openArticle(7, 'https://www.maximonline.ru/longreads/kak-my-obshaemsya-v-socsetyakh-i-kak-eto-vliyaet-na-ustnuyu-rech-id726873/')">
							<div class="card-article__title">Как мы обща<span class="letter">e</span>мся <br>в соцс<span class="letter">e</span>тях</div>
							<div class="card-article__info">
								<div class="card-article__description">О сетевом жаргоне, интимности мата и словах, которые бесят.</div>
								<div class="card-article__text">Татьяна Гартман, руководитель студии тележурналистики «та самая училка»</div>
							</div>
						</div>
					</div>
				</div>
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2000" data-aos-offset="-400">
					<div class="card-article" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(8, 'https://www.maximonline.ru/guide/kak-yazyk-otobrazhaet-menyayushuyusya-realnost-razbiraemsya-vmeste-s-glavredom-maxim-aleksandrom-malenkovym-id730514/')">
						<div class="card-article__title">Как язык отобража<span class="letter">e</span>т м<span class="letter">e</span>няющуюся <span class="line">р<span class="letter">e</span>альность</span>?</div>
						<div class="card-article__bottom">
							<div class="card-article__description">Инсайты от главреда легендарного глянца.</div>
							<div class="card-article__text">Александр Маленков, главный редактор MAXIM</div>
						</div>
					</div>
				</div>
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2800" data-aos-offset="-400">
					<div class="card-article card-article--yellow" @mouseenter="setCursor('readpink')" @mouseleave="setCursor('default')" @click="openArticle(9, 'https://www.maximonline.ru/guide/stanovitsya-li-russkii-yazyk-proshe-a-oshibki-prostitelnee-sprosili-u-filologov-id731518/')">
						<div class="card-article__title">Становится <br>ли русский язык прощ<span class="letter">e</span>?</div>
						<div class="card-article__bottom">
							<div class="card-article__description">Говорим о сокращениях, инфостиле и «кеках».</div>
							<div class="card-article__text">Алексей Глазков и Светлана Друговейко-Должанская, филологи, преподаватели ведущих вузов Москвы</div>
						</div>
					</div>
				</div>
				<div data-aos="fade-right" data-aos-duration="800" data-aos-delay="2900" data-aos-offset="-400">
					<div class="card-article" @mouseenter="setCursor('read')" @mouseleave="setCursor('default')" @click="openArticle(10, 'https://www.woman.ru/news/zachem-vam-padezhi-chto-dumayut-inostrancy-o-russkom-yazyke-id731928/')">
						<div class="card-article__title">«Зач<span class="letter">e</span>м вам пад<span class="letter">e</span>жи?!»: что думают <span class="line">иностранцы</span> о русском язык<span class="letter">e</span></div>
						<div class="card-article__bottom">
							<div class="card-article__description">Почему «павлин» — это смешно, а падежи не так уж нужны.</div>
							<div class="card-article__text">Педро Рохас и Дарио Орельяна, экспаты из Аргентины и Чили</div>
						</div>
					</div>
				</div>
			</div>
			<div class="experts__elements">
				<div class="experts__element-1 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="experts__element-2 parallax" data-speed-x="-10" data-speed-y="-20"></div>
				<div class="experts__element-3 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="experts__element-4 parallax" data-speed-x="-10" data-speed-y="20">
					<span>А</span>
					<span>а</span>
					<span>Б</span>
					<span>б</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';
	import { gsap } from 'gsap';
	import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
	gsap.registerPlugin(ScrollTrigger);
	export default {
		name: 'Experts',
		data() {
			return {
				title: false,
				readyAnim: false,
				drawline: false,
			}
		},
		mounted() {
			ScrollTrigger.create({
				trigger: "#exp",
				start: "bottom bottom",
				once: true,
				scrub: true,
				onToggle: () => {
						this.animate()
					setTimeout(() => {
						this.readyAnim = true;
					}, 400) 
					setTimeout(() => {
						this.drawline = true;
					}, 1400) 
				},
			});
		},
		methods: {
			openArticle(id, url) {
				this.$gtag.event('click', {event_category: 'link','event_label': 'article_'+id})
				if (url !== 'none') {
					window.open(url, '_blank').focus();
				}
			},
			setCursor(status) {
				this.$store.dispatch('setCursor', status)
			},
			animate() {
				if (!this.title) {
					anime.timeline({loop: false})
						.add({
							targets: '.ml10 .lll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 60 * i
						});
					this.title = true;
				}
			}
		}
	}
</script>

<style lang="sass">
	.path4
		stroke-dasharray: 2000
		stroke-dashoffset: 2000
		&.animated
			animation: dash 1s linear forwards
	.hidden
		visibility: hidden !important
		opacity: 0 !important
	.ml10
		position: relative	
		display: block
		margin-bottom: -1vw
	.ml10 .text-wrapper 
		position: relative
		display: inline-block
		overflow: hidden
	
	.ml10 .lll
		transform-origin: 0 100%
		display: inline-block
	.lll
		position: relative
		&--space
			width: vw(14px)
			display: inline-block
			height: 100%
	.experts
		padding-top: vw(178px)
		padding-bottom: vw(101px)
		&__title
			font-family: 'Mutabor'
			font-size: vw(40px)
			line-height: vw(60px)
			color: #000
			text-transform: uppercase
			letter-spacing: vw(3.7px)
			text-align: center
			margin-left: vw(4px)
			margin-bottom: vw(17px)
			span
				&.letter
					font-family: 'Open Sans'
					font-size: vw(48px)
				&.wrapper
					position: relative
			&-el
				width: vw(242px)
				height: vw(71px)
				position: absolute
				top: vw(-7px)
				left: vw(431px)
				z-index: -1
		&__text
			font-size: vw(18px)
			line-height: vw(26px)
			text-transform: uppercase
			color: #000
			font-weight: 500
			letter-spacing: 1.2px
			text-align: center
		&__widget
			display: flex
			justify-content: center
			align-items: center
			margin-top: vw(13px)
			&-icon
				background-image: url(../assets/img/tap.png)
				width: vw(50px)
				height: vw(50px)
				background-size: contain
				background-repeat: no-repeat
			&-text
				font-size: vw(13px)
				color: rgba(0, 0, 0, 0.4)
		&__list
			display: flex
			justify-content: space-between
			flex-wrap: wrap
			margin-top: vw(34px)
			&-column
				display: flex
				flex-direction: column
				justify-content: space-between
		&__content
			position: relative
		&__element
			&-1
				background-image: url(../assets/img/experts-el-1.svg)
				width: vw(60px)
				height: vw(58px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(63px)
				left: vw(-10px)
			&-2
				background-image: url(../assets/img/experts-el-2.svg)
				width: vw(14px)
				height: vw(13px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(172px)
				left: vw(286px)
			&-3
				background-image: url(../assets/img/experts-el-3.svg)
				width: vw(60px)
				height: vw(59px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(621px)
				right: vw(-48px)
			&-4
				font-family: 'Pushkin'
				font-size: vw(52px)
				position: absolute
				color: #EBFF73
				transform: rotate(-5deg)
				position: absolute
				right: vw(145px)
				top: vw(146px)

	.card-article
		width: vw(298px)
		border: 1.2px solid #000
		background: #FCC8E7
		border-radius: 10px
		padding: vw(25px) vw(25px) vw(25px) vw(18px)
		display: flex
		flex-direction: column
		min-height: vw(349px)
		cursor: pointer
		position: relative
		transition: all 300ms
		cursor: none
		margin-bottom: vw(28px)
		&:after
			content: ''
			width: 100%
			height: 100%
			border-radius: vw(10px)
			background: #EBFF73
			position: absolute
			z-index: -1
			top: vw(6px)
			left: vw(-6px)
		&:hover
			.card-article__description
				display: block
		&--small
			min-height: vw(160px)
			justify-content: center
			padding-top: 0
			padding-bottom: 0
			&:hover
				.card-article__title
					display: none
				.card-article__info
					display: block
		&--yellow
			background: #EBFF73
			&:after
				background: #000
		&__title
			font-size: vw(24px)
			color: #000
			line-height: vw(35px)
			font-family: 'Mutabor'
			text-transform: uppercase
			letter-spacing: vw(1.8px)
			transition: all 300ms
			span
				&.letter
					font-family: 'Open Sans'
					font-size: vw(29px)
				&.line
					position: relative
					z-index: 10
					&:after
						content: ''
						width: 104%
						height: vw(8px)
						background-image: url(../assets/img/line.svg)
						background-repeat: no-repeat
						position: absolute
						background-size: contain
						left: 0
						top: vw(28px)
						z-index: 9
						@media screen and (min-width: 1000px)
							background-position: 100% 50%
							background-size: vw(213px)
					&--short
						&:after
							background-position: 100% 50%
		&__text
			font-size: vw(16px)
			line-height: vw(19px)
			color: #000
		&__description
			font-size: vw(17px)
			line-height: vw(20px)
			display: none
			transition: all 300ms
			font-weight: 500
			margin-bottom: vw(6px)
		&__bottom
			margin-top: auto
		&__info
			display: none
			transition: all 300ms
</style>