<template>
	<div class="interactive">
		<div class="interactive__content">
			<div class="interactive__info">
				<div class="interactive__title" data-aos="fade-up" data-aos-duration="1000" data-aos-id="in-title">
					<span>
						давайте проверим, насколько вы владеете современной лексикой.
						<div class="interactive__title-el">
							<svg width="100%" height="100%" viewBox="0 0 97 38" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M94.5404 5.58445C79.951 4.07561 65.3018 2.04421 50.6463 1.61374C38.9561 1.27038 27.2649 0.279999 16.1079 3.41401C9.02884 5.40253 2.81055 6.26142 1.28774 13.5738C-0.428704 21.8161 9.0048 27.0651 16.7387 29.3924C31.445 33.8179 48.0293 37.047 63.5442 36.2555C71.2374 35.863 79.4002 35.9288 86.2659 32.4747C96.0321 27.5614 100.886 17.1001 89.1906 10.4034C79.9487 5.11158 67.0883 5.82065 56.7404 5.11972C47.5197 4.49514 38.6078 7.25829 29.5017 7.39052C23.2229 7.48169 16.7866 10.1962 12.4637 14.1608C8.48836 17.8068 12.5409 24.4386 16.5836 26.7534C21.2588 29.4304 27.3398 30.2399 32.5889 31.3476C40.0772 32.9279 47.9579 34.3966 55.5999 35.1179C63.8408 35.8957 75.8981 36.9882 82.9482 32.1316C87.5942 28.9311 92.3858 26.4179 92.9689 20.7802C93.7579 13.1505 86.7348 10.005 81.1408 4.76749" stroke="#EBFF73" stroke-width="1.8" stroke-linecap="round" class="path2" :class="{'animated': title}"/>
							</svg>
						</div>
					</span>
				</div>
				<div class="interactive__text" data-aos="fade-up" data-aos-duraion="1000" data-aos-delay="600">На карточках ниже — сленговые выражения. Ваша задача — попробовать понять, что они означают. Чтобы проверить себя, кликайте на карточки — они перевернутся, и на обороте найдете правильный ответ.</div>
				<div class="interactive__el-mob-1"></div>
			</div>
			<div class="interactive__list" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
				<div class="interactive__list-text">Скрольте, чтобы увидеть все <br>карточки и кликайте на них, <br>чтобы узнать ответ</div>
				<div class="swiper swiper-interactive">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
			<Card>
				<template v-slot:title>
					<p>Сорри, свалился <br>с короной и не смог <br>прочекать <br>и отфидбэчить бриф! <br>Можно запишу войс?</p>
				</template>
				<template v-slot:text>
					<p>Прошу прощения, заболел коронавирусом и не смог <br>посмотреть вводные данные <br>и дать комментарии. Можно <br>отправлю их голосовым <br>сообщением?</p>
				</template>
			</Card>
						</div>
						<div class="swiper-slide">
			<Card>
				<template v-slot:title>
					<p>Сегодня чилили <br>с крашем <br>и наткнулись <br>на криповый мем.</p>
				</template>
				<template v-slot:text>
					<p>Сегодня отдыхали <br>с молодым человеком <br>и увидели жутковатую <br>картинку.</p>
				</template>
			</Card>
						</div>
						<div class="swiper-slide">
			<Card>
				<template v-slot:title>
					<p>Пришел клиент с новым брифом. Дедлайн там, <br>конечно, огонь! Давайте <br>в обед поштормим, <br>а вечером пошерим <br>инсайты?</p>
				</template>
				<template v-slot:text>
					<p>Клиент обратился <br>к нам с новыми пожеланиями. <br>Сроки, конечно, очень сжатые! <br>Давайте в обед подумаем над <br>новыми идеями, а вечером <br>обсудим?</p>
				</template>
			</Card>
						</div>
						<div class="swiper-slide">
			<Card>
				<template v-slot:title>
					<p>Давайте после синка <br>покатим фичу в прод, <br>чтобы уже можно было <br>тестить на настоящем <br>трафике.</p>
				</template>
				<template v-slot:text>
					<p>Давайте после того, <br>как обсудим выполнение текущих <br>задач, добавим новую функцию <br>на cайт, чтобы можно было ее <br>протестировать на настоящих <br>пользователях.</p>
				</template>
			</Card>
						</div>
						<div class="swiper-slide">
			<Card>
				<template v-slot:title>
					<p>Тиммейт вовремя <br>кинул хелпу, и мы <br>затащили катку <br>на изи.</p>
				</template>
				<template v-slot:text>
					<p>Товарищ по команде <br>вовремя помог, и мы легко <br>выиграли раунд в игре.</p>
				</template>
			</Card>
						</div>
					</div>
					<div class="slider-button slider-button-prev" @click="slidePrev"></div>
					<div class="slider-button slider-button-next" @click="slideNext"></div>
				</div>
			</div>
			<div class="interactive__el-mob-2"></div>
			<div class="interactive__el-mob-3"></div>
			<div class="interactive__el-mob-4"></div>
			<div class="interactive__el-mob-5"></div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper';
	import Card from '@/components/CardMobile.vue'
	import 'swiper/swiper-bundle.css';

	export default {
		name: 'Interactive',
		data() {
			return {
				title: false,
			}
		},

		mounted() {
			this.slider = new Swiper('.swiper-interactive', {
				direction: 'horizontal',
				slidesPerView: 1,
				spaceBetween: 30,
				loop: true,
			});
			document.addEventListener('aos:in:in-title', () => {
				this.title = true;
			});
			document.addEventListener('aos:out:in-title', () => {
				this.title = false;
			});
		},

		components: {
			Card,
		},

		methods: {
			slideNext() {
        this.slider.slideNext();
      },
      slidePrev() {
        this.slider.slidePrev();
      },
		}
	}
</script>

<style lang="sass">
	.path2
		stroke-dasharray: 2000
		stroke-dashoffset: 2000
		&.animated
			animation: dash 1s linear forwards
			animation-delay: 1.5s
	@media screen and (max-width: 1000px)
		.interactive__content
			display: block
		.interactive__info
			width: 100%
			margin-top: 0
		.interactive__title
			font-size: vwm(18px)
			line-height: vwm(26px)
			letter-spacing: 0.6px
			margin-bottom: vwm(15px)
		.interactive__text
			font-size: vwm(18px)
			line-height: vwm(24px)
		.interactive
			padding-top: vwm(163px)
			.swiper
				position: relative
			.slider-button
				top: vwm(-64px)
				&-prev
					left: 0
				&-next
					right: 0
		.interactive__title-el
			width: vwm(97px)
			height: vwm(42px)
			top: vwm(-10px)
			left: vwm(-4px)
		.interactive__list
			margin-top: vwm(50px)
			position: relative
			z-index: 10
		.interactive__list-text
			font-size: vwm(16px)
			line-height: vwm(15px)
			color: rgba(0, 0, 0, 0.4)
			text-align: center
			margin-bottom: vwm(22px)
		.interactive__el-mob-1
			background-image: url(../../assets/img/interactive-el-mob-1.svg)
			width: vwm(6px)
			height: vwm(5px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: vwm(-43px)
			right: vwm(49px)
		.interactive__el-mob-2
			background-image: url(../../assets/img/interactive-el-mob-2.svg)
			width: vwm(6px)
			height: vwm(5px)
			right: vwm(5px)
			top: vwm(226px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
		.interactive__el-mob-3
			background-image: url(../../assets/img/interactive-el-mob-3.svg)
			width: vwm(36px)
			height: vwm(31px)
			left: vwm(45px)
			top: vwm(-97px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
		.interactive__el-mob-4
			background-image: url(../../assets/img/interactive-el-mob-4.svg)
			width: vwm(62px)
			height: vwm(65px)
			right: vwm(-8px)
			top: vwm(-140px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
		.interactive__el-mob-5
			background-image: url(../../assets/img/interactive-el-mob-5.svg)
			width: vwm(59px)
			height: vwm(18px)
			right: vwm(139px)
			top: vwm(-56px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
</style>
