<template>
  <div id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$gtag.pageview({page_path: '/'})
    this.checkVersion();
    window.addEventListener("resize", this.checkVersion);
  },
  methods: {
    checkVersion() {
      if (window.innerWidth > 1000 && this.$route.path == '/mobile') {
         this.$router.push('/')
      }
      if (window.innerWidth < 1000 && this.$route.path == '/') {
         this.$router.push('/mobile')
      }
    }
  },
}
</script>

<style>
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
</style>

<style lang="sass">
  *
    box-sizing: border-box
  body
    font-family: 'Jost', sans-serif
    overflow-x: hidden
    position: relative
    background: #FCC8E7
  .container
    width: vw(1281px)
    margin: auto
    position: relative
    @media screen and (max-width: 1000px)
      width: 100%
      padding-left: vwm(15px)
      padding-right: vwm(15px)
  .wrapper
    position: relative
    overflow: hidden
</style>

<style>
  @font-face {
    font-family: 'Mutabor';
    src: url('./assets/fonts/Mutabor.eot');
    src: url('./assets/fonts/Mutabor.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Mutabor.woff2') format('woff2'),
        url('./assets/fonts/Mutabor.woff') format('woff'),
        url('./assets/fonts/Mutabor.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gogol';
    src: url('./assets/fonts/Gogol.eot');
    src: url('./assets/fonts/Gogol.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Gogol.woff2') format('woff2'),
        url('./assets/fonts/Gogol.woff') format('woff'),
        url('./assets/fonts/Gogol.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pushkin';
    src: url('./assets/fonts/Pushkin.eot');
    src: url('./assets/fonts/Pushkin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Pushkin.woff2') format('woff2'),
        url('./assets/fonts/Pushkin.woff') format('woff'),
        url('./assets/fonts/Pushkin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
</style>