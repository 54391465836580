<template>
	<div class="card__wrapper" @mouseenter="setCursor" @mouseleave="unsetCursor">
		<vue-flip width="92.26667vw" height="61.6vw" :active-click="true" @click.native="onClick">
			<template v-slot:front>
				<div class="card">
					<div class="card__eye"></div>
					<div class="card__title"><slot name="title"></slot></div>
				</div>
			</template>
			<template v-slot:back>
				<div class="card card--open">
					<div class="card__eye"></div>
					<div class="card__text"><slot name="text"></slot></div>
				</div>
			</template>
		</vue-flip>
	</div>
</template>

<script>
	import VueFlip from 'vue-flip';
	export default {
		name: 'Card',
		components: {
			'vue-flip': VueFlip
		},
		methods: {
			setCursor() {
				this.$store.dispatch('setCursor', 'open')
			},
			unsetCursor() {
				this.$store.dispatch('setCursor', 'default')
			},
			onClick() {
        this.$gtag.event('click', {event_category: 'link','event_label': 'flip_card'});
			}
		}
	}
</script>

<style lang="sass">
	.card
		width: vw(407px)
		min-height: vw(197px)
		border: 1.2px solid #000
		border-radius: vw(10px)
		background: #FCC8E7
		margin-bottom: vw(44px)
		position: relative
		transition: all 300ms
		cursor: none
		&__wrapper
			margin-bottom: 3.05556vw
		&:after
			content: ''
			width: 100%
			height: 100%
			border-radius: vw(10px)
			background: #EBFF73
			position: absolute
			z-index: -1
			top: vw(6px)
			left: vw(-6px)
		&--open
			background: #EBFF73
			.card__eye
				background-image: url(../assets/img/eye-open.svg)
				background-position: 50% 84%
			.card__title
				display: none
			.card__text
				display: block
			&:after
				background: #000
		&__eye
			width: vw(89px)
			height: vw(44.5px)
			background: #EBFF73
			border-radius: 0 0 50% 50% / 0 0 100% 100%
			margin: auto
			background-image: url(../assets/img/eye-close.svg)
			background-size: vw(41px)
			background-repeat: no-repeat
			background-position: 50% 53%
		&__title
			font-size: vw(16px)
			line-height: vw(24px)
			color: #000
			text-transform: uppercase
			font-weight: 500
			text-align: center
			margin-top: vw(31px)
			letter-spacing: 1.1px
			transition: all 300ms
		&__text
			font-size: vw(18px)
			line-height: vw(26px)
			color: #000
			font-weight: 500
			text-align: center
			margin-top: vw(21px)
			display: none
			transition: all 300ms
	@media screen and (max-width: 1000px)
		.card
			width: vwm(346px)
			min-height: vwm(231px)
			border-radius: vwm(10px)
			margin-bottom: 0
		.card:after
			border-radius: vwm(10px)
			top: vwm(6px)
			left: vwm(-6px)
		.card__title
			font-size: vwm(18px)
			line-height: vwm(25px)
			margin-top: vwm(16px)
			letter-spacing: 0.4px
		.card__text
			font-size: vwm(18px)
			line-height: vwm(24px)
			margin-top: vwm(12px)
		.card__eye
			width: vwm(78px)
			height: vwm(39px)
			background-size: vwm(36px)
			background-position: 50% 45%
		.card.active .card__eye
			background-position: 50% 100%
			background-size: vwm(41px)
			margin-top: vwm(3px)
</style>