<template>
	<div class="interactive">
		<div class="interactive__content">
			<div class="interactive__info">
				<div class="interactive__title" data-aos="fade-up" data-aos-duration="1000" data-aos-id="in-title">
					<span>
						для начала давайте проверим, насколько вы владеете современной лексикой.
						<div class="interactive__title-el">
							<svg width="100%" height="100%" viewBox="0 0 157 44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M151.118 1.14656C127.341 1.14656 103.382 0.53018 79.6785 1.82553C60.7714 2.85878 41.7546 3.11987 24.2872 8.23801C13.2042 11.4855 3.32068 13.2783 2.08464 22.1946C0.691435 32.2447 16.7778 37.3455 29.6365 39.1688C54.0877 42.6358 81.3676 44.443 106.253 41.5829C118.592 40.1647 131.765 39.2356 142.26 34.2651C157.188 27.1948 163.27 14.1087 143.295 7.55904C127.511 2.3834 106.892 4.81734 90.0896 5.25809C75.1175 5.65083 61.2083 10.0492 46.5473 11.3311C36.4381 12.215 26.5126 16.2496 20.2033 21.5156C14.4012 26.3583 22.0416 33.774 28.9463 36.038C36.9313 38.6562 46.8716 38.8717 55.5203 39.546C67.8582 40.5079 80.8105 41.2881 93.2531 41.2057C106.671 41.1168 126.295 40.9325 136.853 34.2651C143.811 29.8713 151.118 26.2801 151.118 19.4787C151.118 10.2742 139.269 7.38659 129.375 1.82553" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path2" :class="{'animated': title}" />
							</svg>
						</div>
					</span>
				</div>
				<div class="interactive__text" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">На карточках ниже — сленговые выражения. <br>Ваша задача — попробовать понять, что они означают. Чтобы проверить себя, кликайте на карточки — они перевернутся, и на обороте найдете правильный ответ.</div>
				<div class="interactive__el-1 parallax" data-speed-x="10" data-speed-y="20"></div>
			</div>
			<Card>
				<template v-slot:title>
					<p>Сорри, свалился с короной <br>и не смог прочекать <br>и отфидбэчить бриф! Можно <br>запишу войс?</p>
				</template>
				<template v-slot:text>
					<p>Прошу прощения, заболел коронавирусом <br>и не смог посмотреть вводные данные <br>и дать комментарии. Можно отправлю <br>их голосовым сообщением?</p>
				</template>
			</Card>
			<Card>
				<template v-slot:title>
					<p>Сегодня чилили с крашем <br>и наткнулись на криповый <br>мем.</p>
				</template>
				<template v-slot:text>
					<p>Сегодня отдыхали с молодым <br>человеком и увидели жутковатую <br>картинку.</p>
				</template>
			</Card>
			<Card>
				<template v-slot:title>
					<p>Пришел клиент с новым брифом. Дедлайн там, конечно, жесть! <br>Давайте в обед поштормим, <br>а вечером пошерим инсайты?</p>
				</template>
				<template v-slot:text>
					<p>Клиент обратился к нам с новыми <br>пожеланиями. Сроки, конечно, очень <br>сжатые! Давайте в обед подумаем над <br>новыми идеями, а вечером обсудим?</p>
				</template>
			</Card>
			<Card>
				<template v-slot:title>
					<p>Давайте после синка покатим <br>фичу в прод, чтобы уже можно <br>было тестить на настоящем <br>трафике.</p>
				</template>
				<template v-slot:text>
					<p>Давайте после того, как обсудим <br>выполнение текущих задач, добавим новую <br>функцию на cайт, чтобы можно было ее <br>протестировать на настоящих пользователях.</p>
				</template>
			</Card>
			<Card>
				<template v-slot:title>
					<p>Тиммейт вовремя <br>кинул хелпу, и мы <br>затащили катку на изи.</p>
				</template>
				<template v-slot:text>
					<p>Товарищ по команде вовремя <br>помог, и мы легко выиграли <br>раунд в игре.</p>
				</template>
			</Card>
			<div class="interactive__el-2"></div>
		</div>
	</div>
</template>

<script>
	import Card from '@/components/Card.vue'
	export default {
		name: 'Interactive',
		data() {
			return {
				title: false,
			}
		},
		components: {
			Card,
		},
		mounted() {
			document.addEventListener('aos:in:in-title', () => {
				this.title = true;
			});
			document.addEventListener('aos:out:in-title', () => {
				this.title = false;
			});
		}
	}
</script>

<style lang="sass">
	.path2
		stroke-dasharray: 2000
		stroke-dashoffset: 2000
		&.animated
			animation: dash 1s linear forwards
			animation-delay: 1.5s
	.interactive
		padding-top: vw(97px)
		&__title	
			font-size: vw(18px)
			line-height: vw(26px)
			text-transform: uppercase
			color: #000
			font-weight: 500
			letter-spacing: 1.2px
			margin-bottom: vw(15px)
			span
				position: relative
			&-el
				width: vw(157px)
				height: vw(47px)
				position: absolute
				top: vw(-13px)
				left: vw(-24px)
				z-index: -1
		&__text
			font-size: vw(16px)
			line-height: vw(23px)
			color: #000
		&__info
			width: vw(407px)
			margin-top: vw(5px)
			position: relative
		&__el
			&-1
				background-image: url(../assets/img/interactive-el-1.svg)
				width: vw(17px)
				height: vw(15px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				right: vw(5px)
				top: vw(56px)
			&-2
				background-image: url(../assets/img/interactive-el-2.svg)
				width: vw(13px)
				height: vw(12px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				right: vw(461px)
				top: vw(494px)
		&__content
			display: flex
			justify-content: space-between
			flex-wrap: wrap
			position: relative
</style>