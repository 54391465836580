<template>
	<div class="footer">
		<div class="footer__content">
			<div class="footer__social">
				<div class="footer__social-item">
					<a href="javascript:void(0)" class="footer__social-link footer__social-link--vk" data-url="http://slovo.maximonline.ru/" data-title="На языке перемен" @click="share('vk')" data-type="vk" ref="vk"></a>
				</div>
				<div class="footer__social-item">
					<a href="javascript:void(0)" class="footer__social-link footer__social-link--ok" data-url="http://slovo.maximonline.ru/" data-title="На языке перемен" @click="share('ok')" data-type="ok" ref="ok"></a>
				</div>
				<div class="footer__social-item">
					<a href="javascript:void(0)" class="footer__social-link footer__social-link--tw" data-url="http://slovo.maximonline.ru/" data-title="На языке перемен" @click="share('twitter')" data-type="twitter" ref="twitter"></a>
				</div>
				<div class="footer__social-item">
					<a href="javascript:void(0)" class="footer__social-link footer__social-link--tg" data-url="http://slovo.maximonline.ru/" data-title="На языке перемен" @click="share('telegram')" data-type="telegram" ref="telegram"></a>
				</div>
			</div>
			<div class="footer__command" @click="ExpertsOpen = !ExpertsOpen">
				<span>
					Команда проекта
					<div class="footer__command-el">
						<svg width="100%" height="100%" viewBox="0 0 178 55" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M171.42 1.18494C144.247 1.18494 116.865 0.407132 89.7754 2.04174C68.1673 3.3456 46.4339 3.67507 26.4711 10.1337C13.8047 14.2316 2.50935 16.494 1.09674 27.7455C-0.495503 40.4278 17.8889 46.8646 32.5846 49.1654C60.5288 53.5404 91.7059 55.8209 120.146 52.2118C134.248 50.4221 149.303 49.2497 161.297 42.9774C178.358 34.0553 185.309 17.5419 162.48 9.27688C144.441 2.74572 120.876 5.81712 101.674 6.3733C84.5628 6.8689 68.6667 12.4192 51.9111 14.0368C40.3578 15.1523 29.0144 20.2436 21.8038 26.8887C15.1728 32.9997 23.9047 42.3577 31.7957 45.2146C40.9215 48.5186 52.2819 48.7905 62.1661 49.6414C76.2666 50.8552 91.0692 51.8398 105.289 51.7358C120.624 51.6236 143.051 51.391 155.118 42.9774C163.069 37.4329 171.42 32.9011 171.42 24.3184" stroke="#EBFF73" stroke-width="2" stroke-linecap="round"/>
						</svg>
					</div>
				</span>
			</div>
			<div class="footer__copy">© 2022, SHKULEV MEDIA HOLDING Creative Studio</div>
			<div class="footer__block" v-if="ExpertsOpen">
				<div class="footer__block-wrapper">
					<div class="footer__block-close" @click="ExpertsOpen = !ExpertsOpen">
						<span></span>
						<span></span>
					</div>
					<div class="footer__block-title">над про<span class="letter">е</span>ктом работали:</div>
					<div class="footer__block-list">
						<p>Руководитель отдела специальных проектов: <br>Ирина Маханева;</p>
						<p>Креативный менеджер: Анна Филимонова;</p>
						<p>Арт-директор: Майя Богатова;</p>
						<p>Дизайнер: Екатерина Михеева;</p>
						<p>Редактор: Анастасия Ильиных;</p>
						<p>Авторы: Юлия Крышевич, Елена Лазуткина <br>Анна Черемша.</p>
					</div>
					<div class="footer__block-copy">© 2022, SHKULEV MEDIA HOLDING Creative Studio</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import JSShare from "js-share";
	export default {
		name: 'Footer',
		data() {
			return {
				ExpertsOpen: false,
			}
		},
		methods: {
			share(soc) {
				JSShare.go(this.$refs[soc]);
				this.$gtag.event('click', {event_category: 'link','event_label': 'share_'+soc})
			}
		},
	}
</script>

<style lang="sass">
	.footer
		padding-bottom: vw(40px)
		&__content
			display: flex
			align-items: center
			justify-content: space-between
			position: relative
		&__social
			display: flex
			&-title
				display: none
			&-content
				display: flex
			&-item
				margin-right: vw(18px)
			&-link
				width: vw(38px)
				height: vw(38px)
				display: block
				border-radius: 50%
				border: 1px solid #000
				transition: all 300ms
				&:hover
					background-color: #EBFF73
				&--vk
					background-image: url(../assets/img/social/vk.svg)
					background-size: vw(19px)
					background-repeat: no-repeat
					background-position: 50% 54%
				&--ok
					background-image: url(../assets/img/social/ok.svg)
					background-size: vw(11px)
					background-repeat: no-repeat
					background-position: 49% 51%
				&--tw
					background-image: url(../assets/img/social/tw.svg)
					background-size: vw(19px)
					background-repeat: no-repeat
					background-position: 53% 53%
				&--tg
					background-image: url(../assets/img/social/tg.svg)
					background-size: vw(20px)
					background-repeat: no-repeat
					background-position: 39% 51%
		&__command
			font-size: vw(16px)
			color: #000
			letter-spacing: 0.4px
			margin-left: vw(158px)
			cursor: pointer
			position: relative
			span
				position: relative
			&-el
				width: vw(176px)
				height: vw(59px)
				position: absolute
				top: vw(-19px)
				left: vw(-36px)
				z-index: -1
		&__block
			width: vw(480px)
			border: 1.2px solid #000
			position: absolute
			border-radius: vw(10px)
			left: 0
			right: 0
			margin: auto
			bottom: vw(60px)
			z-index: 10
			&-wrapper
				padding: vw(6px) 0 vw(12px) 0
				background: #EBFF73
				color: #000
				border-radius: vw(10px)
				z-index: 10
			&:after
				content: ''
				width: 100%
				height: 100%
				border-radius: vw(10px)
				background: #000
				position: absolute
				top: vw(6px)
				left: vw(-7px)
				z-index: -1
			&-close
				cursor: pointer
				width: vw(39px)
				height: vw(39px)
				padding-top: vw(21px)
				margin-left: auto
				margin-right: vw(9px)
				span
					width: 100%
					height: 1px
					background: #000
					display: block
					&:nth-child(1)
						transform: rotate(45deg)
					&:nth-child(2)
						transform: rotate(-45deg)
			&-title
				font-size: vw(20px)
				font-family: 'Mutabor'
				text-transform: uppercase
				text-align: center
				letter-spacing: vw(3.2px)
				span
					&.letter
						font-family: 'Open Sans'
						font-size: vw(24px)
			&-list
				font-size: vw(17px)
				line-height: vw(22px)
				text-align: center
				letter-spacing: 0
				margin-top: vw(24px)
				p
					margin-bottom: vw(7px)
			&-copy
				text-align: center
				font-size: vw(15px)
				margin-top: vw(38px)
				letter-spacing: 0
		&__copy
			font-size: vw(16px)
			color: #000
			letter-spacing: 0.5px
</style>