<template>
	<div class="why">
		<div class="why__content">
			<div class="why__title" id="why2">
					<div class="why__title-el">
						<svg width="100%" height="100%" viewBox="0 0 115 49" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M110.418 1.95397C92.9713 1.95397 75.3904 1.27889 57.9979 2.69761C44.1245 3.82926 30.1706 4.11522 17.3536 9.7208C9.22123 13.2775 1.96907 15.2411 1.06211 25.0066C0.0398188 36.0138 11.8435 41.6005 21.2787 43.5974C39.2202 47.3945 59.2373 49.3738 77.497 46.2414C86.5513 44.6881 96.2173 43.6706 103.918 38.2267C114.872 30.483 119.334 16.1506 104.678 8.97717C93.0954 3.30861 77.9661 5.97435 65.6371 6.45708C54.6511 6.88722 44.4451 11.7044 33.6873 13.1085C26.2695 14.0766 18.9865 18.4954 14.357 24.2629C10.0996 29.5668 15.7059 37.6888 20.7723 40.1684C26.6314 43.036 33.9253 43.272 40.2714 44.0105C49.3246 45.064 58.8285 45.9185 67.9585 45.8283C77.804 45.7309 92.2034 45.529 99.9505 38.2267C105.056 33.4144 110.418 29.4812 110.418 22.032C110.418 11.951 101.723 8.78829 94.4638 2.69761" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path3" :class="{'animated': drawline}"/>
						</svg>
					</div>
					<div class="why__title-QuestionMarks">???</div>
				<span class="wrapper">
					<div class="wrapper" :class="{'hidden': !readyAnim}">
						<span class="ml8">
							<span class="text-wrapper">
								<span class="ll">П</span>
								<span class="ll">о</span>
								<span class="ll">ч</span>
								<span class="ll letter">е</span>
								<span class="ll">м</span>
								<span class="ll">у</span>
								<span class="ll ll--space"> </span>
								<span class="ll">и</span>
								<span class="ll ll--space"> </span>
								<span class="ll">к</span>
								<span class="ll">а</span>
								<span class="ll">к</span>
							</span>
						</span>
						<span class="ml9">
							<span class="text-wrapper">
								<span class="ll">я</span>
								<span class="ll">з</span>
								<span class="ll">ы</span>
								<span class="ll">к</span>
								<span class="ll ll--space"> </span>
								<span class="ll">м</span>
								<span class="ll letter">е</span>
								<span class="ll">н</span>
								<span class="ll">я</span>
								<span class="ll letter">е</span>
								<span class="ll">т</span>
								<span class="ll">с</span>
								<span class="ll">я</span>
							</span>
						</span>
					</div>
				</span>
			</div>
			<div class="why__info">
				<div class="why__info-left">
					<div class="why__info-item why__info-item--1" data-aos="fade-up" data-aos-duration="1000">
						<span class="wrapper">Появляются новые явления <br>и предметы <span class="arrow"></span> <span class="indent"></span>слова для их обозначения отражаются в языке (пример: “удаленка”);</span>
					</div>
					<div class="why__info-item why__info-item--2" data-aos="fade-up" data-aos-duration="1000">
						<span class="wrapper">Другие явления отмирают <br><span class="arrow"></span> <span class="indent"></span>слова для их обозначения практически перестаем использовать (пример: “отправить факс”);</span>
					</div>
					<div class="why__info-item why__info-item--3" data-aos="fade-up" data-aos-duration="1000">
						<span class="wrapper">Происходят значимые события <span class="arrow"></span> <br><span class="indent"></span>для них придумывают новые слова, которые потом <br>идут по пути упрощения <br>и интегрируются в язык (пример: <br>в начале 2020 года все говорили “коронавирус”, сейчас — <br>в основном используют “ковид”);</span>
					</div>
				</div>
				<div class="why__info-right">
					<div class="why__info-item why__info-item--4" data-aos="fade-up" data-aos-duration="1000">
						<span class="wrapper">Стало много переписок — <br>у них свой специфический <br>стиль, много сокращений <span class="arrow"></span> <br><span class="indent"></span>этот стиль отражается <br>и на устной речи (пример: “лол”, “кмк”);</span>
					</div>
					<div class="why__info-item why__info-item--5" data-aos="fade-up" data-aos-duration="1000">
						<span class="wrapper">Канцелярит в коммуникации <br>с аудиторией постепенно становится дурным тоном <span class="arrow"></span> <br><span class="indent"></span>язык становится менее “формальным”, более дружественным (пример: “Скоро здесь появится новая станция метро” вместо “Ведется строительство станции метрополитена глубокого заложения”).</span>
					</div>
				</div>
			</div>
			<div class="why__elements">
				<div class="why__element-1">
					<span>А</span>
					<span>а</span>
					<span>Б</span>
					<span>б</span>
				</div>
				<div class="why__element-3"></div>
				<div class="why__element-6"></div>
				<div class="why__element-7"></div>
				<div class="why__element-8"></div>
				<div class="why__quote">“Язык всем знаниям <br>и всей природе ключ”.</div>
			</div>
		</div>
	</div>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';
	import { gsap } from 'gsap';
	import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
	gsap.registerPlugin(ScrollTrigger);
	export default {
		name: 'Why',
		mounted() {
			ScrollTrigger.create({
				trigger: "#why2",
				start: "bottom bottom",
				once: true,
				scrub: true,
				onToggle: () => {
						this.animate()
					setTimeout(() => {
						this.readyAnim = true;
					}, 400) 
					setTimeout(() => {
						this.drawline = true;
					}, 1400) 
				},
			});
		},
		data() {
			return {
				title: false,
				readyAnim: false,
				drawline: false,
			}
		},
		methods: {
			animate() {
				if (!this.title) {
					anime.timeline({loop: false})
						.add({
							targets: '.ml8 .ll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 60 * i
						});
					anime.timeline({loop: false})
						.add({
							targets: '.ml9 .ll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 50 * i
						});
					this.title = true;
				}
			}
		}
	}
</script>

<style lang="sass">
	@media screen and (max-width: 1000px)
		.path3
			stroke-dasharray: 2000
			stroke-dashoffset: 2000
			&.animated
				animation: dash 1s linear forwards
		.hidden
			visibility: hidden !important
			opacity: 0 !important
		.ml8
			position: relative
			display: block
			margin-bottom: -2.6vw
			margin-top: -3vw
		.ml8 .text-wrapper 
			position: relative
			display: inline-block
			overflow: hidden
		
		.ml8 .ll
			transform-origin: 0 100%
			display: inline-block
		.ll
			position: relative
			&--space
				width: 2.6vw
				display: inline-block
				height: 100%
		.ml9
			position: relative	
			display: block
			margin-bottom: -1vw
		.ml9 .text-wrapper 
			position: relative
			display: inline-block
			overflow: hidden
		
		.ml9 .ll
			transform-origin: 0 100%
			display: inline-block
		.why__title
			font-size: vwm(30px)
			line-height: vwm(45px)
			letter-spacing: 2.8px
			margin-left: vwm(4px)
			margin-bottom: vwm(35px)
		.why__title span.letter
			font-size: vwm(36px)
		.why
			padding-top: vwm(147px)
			padding-bottom: vwm(66px)
		.why__title-el
			width: vwm(117px)
			height: vwm(49px)
			top: vwm(34px)
			left: vwm(0px)
		.why__title-QuestionMarks
			font-size: vwm(46px)
			top: vwm(-50px)
			right: vwm(-8px)
		.why__info
			flex-direction: column
			padding-left: vwm(30px)
			padding-right: 0
		.why__info-item
			width: 100%
			margin-bottom: vwm(20px) !important
			font-size: vwm(16px)
			line-height: vwm(24px)
		.why__info-item span.indent
			padding-left: vwm(68px)
		.why__info-item span.arrow
			width: vwm(59px)
			height: vwm(18px)
		.why__info-item--1 span.arrow
			top: vwm(22px)
			right: vwm(139px)
		.why__info-item--2 span.arrow
			top: vwm(22px)
			right: vwm(252px)
		.why__info-item--3 span.arrow
			top: vwm(26px)
			right: vwm(254px)
		.why__info-item--4 span.arrow
			top: vwm(72px)
			left: vwm(2px)
		.why__info-item--5 span.arrow
			top: vwm(74px)
			left: vwm(2px)
		.why__info-item--1
			line-height: vwm(22px)
			margin-bottom: vwm(30px) !important
		.why__info-item--2
			line-height: vwm(22px)
		.why__info-item:before
			width: vwm(13px)
			height: vwm(10px)
			left: vwm(-27px)
			top: vwm(6px)
		.why__quote
			display: none
		.why__element-1
			font-size: vwm(40px)
			left: vwm(6px)
			top: vwm(-75px)
		.why__element-6
			width: vwm(42px)
			height: vwm(42px)
			top: vwm(993px)
			left: vwm(284px)
		.why__element-3
			background-image: url(../../assets/img/why-el-mob-1.svg)
			width: vwm(44px)
			height: vwm(44px)
			top: vwm(-149px)
			right: vwm(60px)
		.why__element-7
			width: vwm(62px)
			height: vwm(65px)
			top: vwm(547px)
			right: vwm(-8px)
		.why__element-8
			background-image: url(../../assets/img/why-el-mob-2.svg)
			width: vwm(59px)
			height: vwm(18px)
			top: vwm(1079px)
			left: vwm(-4px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
</style>