<template>
	<div class="footer">
		<div class="footer__content">
			<div class="footer__social">
				<div class="footer__social-title">Поделиться проектом:</div>
				<div class="footer__social-content">
					<div class="footer__social-item">
						<a href="javascript:void(0)" class="footer__social-link footer__social-link--vk"></a>
					</div>
					<div class="footer__social-item">
						<a href="javascript:void(0)" class="footer__social-link footer__social-link--ok"></a>
					</div>
					<div class="footer__social-item">
						<a href="javascript:void(0)" class="footer__social-link footer__social-link--tw"></a>
					</div>
					<div class="footer__social-item">
						<a href="javascript:void(0)" class="footer__social-link footer__social-link--tg"></a>
					</div>
				</div>
			</div>
			<div class="footer__command" @click="ExpertsOpen = !ExpertsOpen">
				<span>
					Команда проекта
					<div class="footer__command-el">
						<svg width="100%" height="100%" viewBox="0 0 166 40" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M159.801 1.92257C134.481 1.92257 108.965 1.37957 83.7226 2.52071C63.5877 3.43095 43.3361 3.66096 24.7344 8.1698C12.9317 11.0306 2.40644 12.61 1.09014 20.4649C-0.393537 29.3185 16.7374 33.8121 30.4311 35.4183C56.47 38.4726 85.5214 40.0646 112.022 37.5451C125.163 36.2957 139.191 35.4772 150.368 31.0985C166.265 24.8698 172.742 13.3416 151.47 7.57166C134.661 3.01217 112.703 5.15635 94.8097 5.54463C78.8654 5.89062 64.053 9.76533 48.4399 10.8947C37.6743 11.6733 27.1043 15.2277 20.3853 19.8667C14.2065 24.1329 22.343 30.6658 29.696 32.6603C38.1996 34.9668 48.7854 35.1567 57.9956 35.7506C71.1347 36.598 84.9281 37.2854 98.1787 37.2128C112.468 37.1345 133.366 36.9721 144.61 31.0985C152.019 27.2277 159.801 24.064 159.801 18.0723" stroke="#EBFF73" stroke-width="2" stroke-linecap="round"/>
						</svg>
					</div>
				</span>
			</div>
			<div class="footer__copy">© 2022, SHKULEV MEDIA HOLDING Creative Studio</div>
			<div class="footer__elements">
				<div class="footer__element-1"></div>
				<div class="footer__element-2"></div>
			</div>
			<div class="footer__block" v-if="ExpertsOpen">
				<div class="footer__block-wrapper">
					<div class="footer__block-close" @click="ExpertsOpen = !ExpertsOpen">
						<span></span>
						<span></span>
					</div>
					<div class="footer__block-title">команда про<span class="letter">e</span>кта</div>
					<div class="footer__block-list">
						<p>Руководитель отдела специальных <br>проектов: Ирина Маханева;</p>
						<p>Креативный менеджер: Анна Филимонова;</p>
						<p>Арт-директор: Майя Богатова;</p>
						<p>Дизайнер: Екатерина Михеева;</p>
						<p>Редактор: Анастасия Ильиных;</p>
						<p>Авторы: Юлия Крышевич, Елена Лазуткина <br>Анна Черемша.</p>
					</div>
					<div class="footer__block-copy">© 2022, SHKULEV MEDIA HOLDING Creative Studio</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				ExpertsOpen: false,
			}
		}
	}
</script>

<style lang="sass">
	@media screen and (max-width: 1000px)
		.footer__content
			flex-direction: column
			position: relative
		.footer__social
			display: block
		.footer__social-content
			justify-content: center
			margin-top: vwm(22px)
		.footer__social-title
			display: block
			font-size: vwm(18px)
			color: #000
			text-align: center
		.footer__social-link
			width: vwm(45px)
			height: vwm(45px)
		.footer__social-item
			margin-right: vwm(11px)
			margin-left: vwm(11px)
		.footer__social-link--vk
			background-size: vwm(23px)
		.footer__social-link--ok
			background-size: vwm(13px)
		.footer__social-link--tw
			background-size: vwm(23px)
			background-position: 50% 51%
		.footer__social-link--tg
			background-size: vwm(24px)
			background-position: 51% 52%
		.footer__command
			font-size: vwm(16px)
			margin-left: 0
			margin-top: vwm(46px)
			letter-spacing: 0
		.footer__command-el
			width: vwm(166px)
			height: vwm(49px)
			left: vwm(-28px)
			top: vwm(-12px)
		.footer__copy
			font-size: vwm(14px)
			letter-spacing: 0
			margin-top: vwm(25px)
		.footer
			padding-bottom: vwm(27px)
		.footer__element-1
			background-image: url(../../assets/img/footer-el-1.svg)
			width: vwm(44px)
			height: vwm(44px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: vwm(95px)
			left: vwm(-15px)
		.footer__element-2
			background-image: url(../../assets/img/footer-el-2.svg)
			width: vwm(6px)
			height: vwm(5px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: vwm(102px)
			right: vwm(48px)
		.footer__block
			width: vwm(345px)
			bottom: vwm(80px)
			border-radius: vwm(10px)
		.footer__block-title
			font-size: vwm(18px)
			letter-spacing: 1.7px
			margin-top: vwm(-12px)
		.footer__block-title span.letter
			font-size: vwm(21px)
		.footer__block-list
			font-size: vwm(16px)
			line-height: vwm(19px)
			margin-top: vwm(25px)
		.footer__block-copy
			font-size: vwm(13px)
			margin-top: vwm(32px)
		.footer__block-wrapper
			padding: vwm(7px) 0 vwm(21px) 0
			border-radius: vwm(10px)
		.footer__block-list p
			margin-bottom: vwm(7px)
		.footer__block-close
			width: vwm(38px)
			height: vwm(38px)
			margin-right: vwm(9px)
			padding-top: vwm(19px)
		.footer__block:after
			left: vwm(-6px)
			top: vwm(7px)
			border-radius: vwm(10px)
</style>