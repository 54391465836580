<template>
	<div class="why">
		<div class="why__content">
			<div class="why__title" id="why">
					<div class="why__title-el">
						<svg width="100%" height="100%" viewBox="0 0 163 65" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M156.896 1.21984C132.039 1.21984 106.99 0.29527 82.2094 2.23829C62.4428 3.78817 42.5617 4.1798 24.3002 11.857C12.7134 16.7282 2.38071 19.4174 1.08849 32.7919C-0.368046 47.867 16.4495 55.5183 29.8927 58.2532C55.4553 63.4536 83.9753 66.1644 109.991 61.8744C122.892 59.7471 136.664 58.3535 147.635 50.8977C163.242 40.2922 169.6 20.663 148.718 10.8386C132.216 3.07511 110.66 6.72601 93.0936 7.38714C77.441 7.97624 62.8996 14.5737 47.5721 16.4966C37.0034 17.8225 26.6268 23.8745 20.0307 31.7734C13.9649 39.0374 21.9526 50.161 29.1711 53.557C37.5191 57.4843 47.9113 57.8076 56.953 58.819C69.8518 60.2618 83.3928 61.4322 96.401 61.3085C110.429 61.1752 130.945 60.8987 141.983 50.8977C149.257 44.307 156.896 38.9201 156.896 28.7181C156.896 14.9114 144.508 10.5799 134.165 2.23829" stroke="#EBFF73" stroke-width="2" stroke-linecap="round" class="path3" :class="{'animated': drawline}" />
						</svg>
					</div>
					<div class="why__title-QuestionMarks parallax" data-speed-x="5" data-speed-y="-20">???</div>
				<span class="wrapper" :class="{'hidden': !readyAnim}">
					<span class="ml8">
						<span class="text-wrapper">
							<span class="ll">П</span>
							<span class="ll">о</span>
							<span class="ll">ч</span>
							<span class="ll letter">е</span>
							<span class="ll">м</span>
							<span class="ll">у</span>
							<span class="ll ll--space"> </span>
							<span class="ll">и</span>
							<span class="ll ll--space"> </span>
							<span class="ll">к</span>
							<span class="ll">а</span>
							<span class="ll">к</span>
						</span>
					</span>
					<span class="ml9">
						<span class="text-wrapper">
							<span class="ll">я</span>
							<span class="ll">з</span>
							<span class="ll">ы</span>
							<span class="ll">к</span>
							<span class="ll ll--space"> </span>
							<span class="ll">м</span>
							<span class="ll letter">е</span>
							<span class="ll">н</span>
							<span class="ll">я</span>
							<span class="ll letter">е</span>
							<span class="ll">т</span>
							<span class="ll">с</span>
							<span class="ll">я</span>
						</span>
					</span>
				</span>
			</div>
			<div class="why__info">
				<div class="why__info-left">
					<div class="why__info-item why__info-item--1" data-aos="fade-up" data-aos-delay="500" data-aos-duration="900">
						<span class="wrapper">Появляются новые явления и предметы <span class="arrow"></span> <span class="indent"></span>слова <br>для их обозначения отражаются в языке (пример: “удаленка”);</span>
					</div>
					<div class="why__info-item why__info-item--2" data-aos="fade-up" data-aos-delay="900" data-aos-duration="900">
						<span class="wrapper">Другие явления отмирают <span class="arrow"></span> <span class="indent"></span>слова для их обозначения практически перестаем использовать <br>(пример: “отправить факс”);</span>
					</div>
					<div class="why__info-item why__info-item--3" data-aos="fade-up" data-aos-delay="1200" data-aos-duration="900">
						<span class="wrapper">Происходят значимые события <span class="arrow"></span> <span class="indent"></span>для них придумывают новые слова, которые потом идут по пути упрощения и интегрируются в язык (пример: в начале 2020 года все говорили “коронавирус”, сейчас — в основном используют “ковид”);</span>
					</div>
				</div>
				<div class="why__info-right">
					<div class="why__info-item why__info-item--4" data-aos="fade-up" data-aos-delay="1500" data-aos-duration="900">
						<span class="wrapper">Стало много переписок — у них свой специфический <br>стиль, много сокращений <span class="arrow"></span> <span class="indent"></span>этот стиль отражается <br>и на устной речи (пример: “лол”, “кмк”);</span>
					</div>
					<div class="why__info-item why__info-item--5" data-aos="fade-up" data-aos-delay="2000" data-aos-duration="900">
						<span class="wrapper">Канцелярит в коммуникации с аудиторией постепенно становится дурным тоном <span class="arrow"></span> <span class="indent"></span>язык становится менее “формальным”, более дружественным (пример: “Скоро <br>здесь появится новая станция метро” вместо “Ведется строительство станции метрополитена глубокого заложения”).</span>
					</div>
				</div>
			</div>
			<div class="why__elements">
				<div class="why__element-1 parallax" data-speed-x="-20" data-speed-y="10">
					<span>А</span>
					<span>а</span>
					<span>Б</span>
					<span>б</span>
				</div>
				<div class="why__element-2 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="why__element-3 parallax" data-speed-x="-10" data-speed-y="-20"></div>
				<div class="why__element-4 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="why__element-5 parallax" data-speed-x="-10" data-speed-y="-20"></div>
				<div class="why__element-6 parallax" data-speed-x="10" data-speed-y="20"></div>
				<div class="why__element-7 parallax" data-speed-x="-10" data-speed-y="-20"></div>
				<div class="why__quote">“Язык всем знаниям <br>и всей природе ключ”.</div>
			</div>
		</div>
	</div>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';
	import { gsap } from 'gsap';
	import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
	gsap.registerPlugin(ScrollTrigger);
	export default {
		name: 'Why',
		data() {
			return {
				title: false,
				readyAnim: false,
				drawline: false,
			}
		},
		mounted() {
			ScrollTrigger.create({
				trigger: "#why",
				start: "bottom bottom",
				once: true,
				scrub: true,
				onToggle: () => {
						this.animate()
					setTimeout(() => {
						this.readyAnim = true;
					}, 400) 
					setTimeout(() => {
						this.drawline = true;
					}, 1400) 
				},
			});
		},
		methods: {
			animate() {
				if (!this.title) {
					anime.timeline({loop: false})
						.add({
							targets: '.ml8 .ll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 60 * i
						});
					anime.timeline({loop: false})
						.add({
							targets: '.ml9 .ll',
							translateY: ["10vw", 0],
							translateX: ["0", 0],
							translateZ: 0,
							rotateZ: [20, 0],
							duration: 1500,
							easing: "easeOutExpo",
							delay: (el, i) => 50 * i
						});
					this.title = true;
				}
			}
		}
	}
</script>

<style lang="sass">
	.path3
		stroke-dasharray: 2000
		stroke-dashoffset: 2000
		&.animated
			animation: dash 1s linear forwards
	.hidden
		visibility: hidden !important
		opacity: 0 !important
	.ml8
		position: relative
		display: block
		margin-bottom: -0.9vw
	.ml8 .text-wrapper 
		position: relative
		display: inline-block
		overflow: hidden
	
	.ml8 .ll
		transform-origin: 0 100%
		display: inline-block
	.ll
		position: relative
		&--space
			width: 1vw
			display: inline-block
			height: 100%
	.ml9
		position: relative	
		display: block
		margin-bottom: -1vw
	.ml9 .text-wrapper 
		position: relative
		display: inline-block
		overflow: hidden
	
	.ml9 .ll
		transform-origin: 0 100%
		display: inline-block
	.why
		padding-top: vw(115px)
		&__title
			font-family: 'Mutabor'
			font-size: vw(40px)
			line-height: vw(60px)
			color: #000
			text-transform: uppercase
			letter-spacing: vw(3.5px)
			text-align: center
			margin-left: vw(11px)
			margin-bottom: vw(66px)
			@media screen and (min-width: 1000px)
				position: relative
			&-QuestionMarks
				font-size: vw(56px)
				font-family: 'Pushkin'
				transform: rotate(-5deg)
				position: absolute
				color: #EBFF73
				right: 19.16111vw
				top: vw(84px)
			span
				&.letter
					font-family: 'Open Sans'
					font-size: vw(48px)
				&.wrapper
					position: relative
			&-el
				width: vw(164px)
				height: vw(65px)
				position: absolute
				top: vw(54px)
				left: 28.09722vw
				z-index: -1
		&__info
			display: flex
			justify-content: space-between
			padding-left: vw(63px)
			padding-right: vw(2px)
			&-left
				.why__info-item
					margin-bottom: vw(21px)
			&-right
				.why__info-item
					margin-bottom: vw(50px)
			&-item
				width: vw(550px)
				font-size: vw(16px)
				line-height: vw(24px)
				color: #000
				font-weight: 500
				text-transform: uppercase
				display: flex
				position: relative
				&:before
					content: ''
					background-image: url(../assets/img/point.svg)
					width: vw(11px)
					height: vw(10px)
					position: absolute
					background-size: contain
					background-repeat: no-repeat
					left: vw(-31px)
					top: vw(7px)
				span
					&.wrapper
						position: relative
					&.arrow
						background-image: url(../assets/img/arrow.svg)
						width: vw(59px)
						height: vw(18px)
						display: block
						background-repeat: no-repeat
						background-size: contain
						position: absolute
					&.indent
						padding-left: vw(68px)
				&--1
					span.arrow
						top: vw(2px)
						right: vw(123px)
				&--2
					span.arrow
						top: vw(2px)
						right: vw(247px)
				&--3
					span.arrow
						top: vw(3px)
						right: vw(194px)
				&--4
					span.arrow
						top: vw(26px)
						left: vw(250px)
				&--5
					span.arrow
						top: vw(25px)
						left: vw(250px)
		&__content
			position: relative
		&__element
			&-1
				font-family: 'Pushkin'
				font-size: vw(60px)
				position: absolute
				color: #EBFF73
				transform: rotate(-5deg)
				position: absolute
				left: vw(168px)
				top: vw(-40px)
			&-2
				background-image: url(../assets/img/why-el-2.svg)
				width: vw(9px)
				height: vw(6px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(23px)
				right: vw(355px)
			&-3
				background-image: url(../assets/img/why-el-3.svg)
				width: vw(72px)
				height: vw(47px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(-46px)
				right: vw(37px)
			&-4
				background-image: url(../assets/img/why-el-4.svg)
				width: vw(47px)
				height: vw(41px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(45px)
				left: vw(-21px)
			&-5
				background-image: url(../assets/img/why-el-5.svg)
				width: vw(13px)
				height: vw(12px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(412px)
				right: vw(-47px)
			&-6
				background-image: url(../assets/img/why-el-6.svg)
				width: vw(52px)
				height: vw(52px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(529px)
				left: vw(609px)
			&-7
				background-image: url(../assets/img/why-el-7.svg)
				width: vw(96px)
				height: vw(100px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: vw(536px)
				right: vw(48px)
		&__quote
			font-size: vw(43px)
			line-height: vw(38px)
			color: #EBFF73
			font-family: 'Gogol'
			transform: rotate(-5deg)
			position: absolute
			top: vw(608px)
			left: vw(-2px)
</style>